@font-face {
	font-family: 'FontAwesome';
	src:url('/static/fonts/fontawesome/fontawesome-webfont.eot');
	src:url('/static/fonts/fontawesome/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
		url('/static/fonts/fontawesome/fontawesome-webfont.woff') format('woff'),
		url('/static/fonts/fontawesome/fontawesome-webfont.ttf') format('truetype'),
		url('/static/fonts/fontawesome/fontawesome-webfont.svg#fontawesomeregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
.gem-print-icon.gem-icon-pack-fontawesome {
	display: inline-block;
	font-family: 'FontAwesome';
	font-feature-settings: normal;
	font-kerning: auto;
	font-language-override: normal;
	font-size: inherit;
	font-size-adjust: none;
	font-stretch: normal;
	font-style: normal;
	font-synthesis: weight style;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	text-rendering: auto;
	text-align: center;
	vertical-align: middle;
	font-size: 14px;
}
.fa-glass:before {
  content: "\F000";
}
.fa-music:before {
  content: "\F001";
}
.fa-search:before {
  content: "\F002";
}
.fa-envelope-o:before {
  content: "\F003";
}
.fa-heart:before {
  content: "\F004";
}
.fa-star:before {
  content: "\F005";
}
.fa-star-o:before {
  content: "\F006";
}
.fa-user:before {
  content: "\F007";
}
.fa-film:before {
  content: "\F008";
}
.fa-th-large:before {
  content: "\F009";
}
.fa-th:before {
  content: "\F00A";
}
.fa-th-list:before {
  content: "\F00B";
}
.fa-check:before {
  content: "\F00C";
}
.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\F00D";
}
.fa-search-plus:before {
  content: "\F00E";
}
.fa-search-minus:before {
  content: "\F010";
}
.fa-power-off:before {
  content: "\F011";
}
.fa-signal:before {
  content: "\F012";
}
.fa-gear:before,
.fa-cog:before {
  content: "\F013";
}
.fa-trash-o:before {
  content: "\F014";
}
.fa-home:before {
  content: "\F015";
}
.fa-file-o:before {
  content: "\F016";
}
.fa-clock-o:before {
  content: "\F017";
}
.fa-road:before {
  content: "\F018";
}
.fa-download:before {
  content: "\F019";
}
.fa-arrow-circle-o-down:before {
  content: "\F01A";
}
.fa-arrow-circle-o-up:before {
  content: "\F01B";
}
.fa-inbox:before {
  content: "\F01C";
}
.fa-play-circle-o:before {
  content: "\F01D";
}
.fa-rotate-right:before,
.fa-repeat:before {
  content: "\F01E";
}
.fa-refresh:before {
  content: "\F021";
}
.fa-list-alt:before {
  content: "\F022";
}
.fa-lock:before {
  content: "\F023";
}
.fa-flag:before {
  content: "\F024";
}
.fa-headphones:before {
  content: "\F025";
}
.fa-volume-off:before {
  content: "\F026";
}
.fa-volume-down:before {
  content: "\F027";
}
.fa-volume-up:before {
  content: "\F028";
}
.fa-qrcode:before {
  content: "\F029";
}
.fa-barcode:before {
  content: "\F02A";
}
.fa-tag:before {
  content: "\F02B";
}
.fa-tags:before {
  content: "\F02C";
}
.fa-book:before {
  content: "\F02D";
}
.fa-bookmark:before {
  content: "\F02E";
}
.fa-print:before {
  content: "\F02F";
}
.fa-camera:before {
  content: "\F030";
}
.fa-font:before {
  content: "\F031";
}
.fa-bold:before {
  content: "\F032";
}
.fa-italic:before {
  content: "\F033";
}
.fa-text-height:before {
  content: "\F034";
}
.fa-text-width:before {
  content: "\F035";
}
.fa-align-left:before {
  content: "\F036";
}
.fa-align-center:before {
  content: "\F037";
}
.fa-align-right:before {
  content: "\F038";
}
.fa-align-justify:before {
  content: "\F039";
}
.fa-list:before {
  content: "\F03A";
}
.fa-dedent:before,
.fa-outdent:before {
  content: "\F03B";
}
.fa-indent:before {
  content: "\F03C";
}
.fa-video-camera:before {
  content: "\F03D";
}
.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "\F03E";
}
.fa-pencil:before {
  content: "\F040";
}
.fa-map-marker:before {
  content: "\F041";
}
.fa-adjust:before {
  content: "\F042";
}
.fa-tint:before {
  content: "\F043";
}
.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\F044";
}
.fa-share-square-o:before {
  content: "\F045";
}
.fa-check-square-o:before {
  content: "\F046";
}
.fa-arrows:before {
  content: "\F047";
}
.fa-step-backward:before {
  content: "\F048";
}
.fa-fast-backward:before {
  content: "\F049";
}
.fa-backward:before {
  content: "\F04A";
}
.fa-play:before {
  content: "\F04B";
}
.fa-pause:before {
  content: "\F04C";
}
.fa-stop:before {
  content: "\F04D";
}
.fa-forward:before {
  content: "\F04E";
}
.fa-fast-forward:before {
  content: "\F050";
}
.fa-step-forward:before {
  content: "\F051";
}
.fa-eject:before {
  content: "\F052";
}
.fa-chevron-left:before {
  content: "\F053";
}
.fa-chevron-right:before {
  content: "\F054";
}
.fa-plus-circle:before {
  content: "\F055";
}
.fa-minus-circle:before {
  content: "\F056";
}
.fa-times-circle:before {
  content: "\F057";
}
.fa-check-circle:before {
  content: "\F058";
}
.fa-question-circle:before {
  content: "\F059";
}
.fa-info-circle:before {
  content: "\F05A";
}
.fa-crosshairs:before {
  content: "\F05B";
}
.fa-times-circle-o:before {
  content: "\F05C";
}
.fa-check-circle-o:before {
  content: "\F05D";
}
.fa-ban:before {
  content: "\F05E";
}
.fa-arrow-left:before {
  content: "\F060";
}
.fa-arrow-right:before {
  content: "\F061";
}
.fa-arrow-up:before {
  content: "\F062";
}
.fa-arrow-down:before {
  content: "\F063";
}
.fa-mail-forward:before,
.fa-share:before {
  content: "\F064";
}
.fa-expand:before {
  content: "\F065";
}
.fa-compress:before {
  content: "\F066";
}
.fa-plus:before {
  content: "\F067";
}
.fa-minus:before {
  content: "\F068";
}
.fa-asterisk:before {
  content: "\F069";
}
.fa-exclamation-circle:before {
  content: "\F06A";
}
.fa-gift:before {
  content: "\F06B";
}
.fa-leaf:before {
  content: "\F06C";
}
.fa-fire:before {
  content: "\F06D";
}
.fa-eye:before {
  content: "\F06E";
}
.fa-eye-slash:before {
  content: "\F070";
}
.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\F071";
}
.fa-plane:before {
  content: "\F072";
}
.fa-calendar:before {
  content: "\F073";
}
.fa-random:before {
  content: "\F074";
}
.fa-comment:before {
  content: "\F075";
}
.fa-magnet:before {
  content: "\F076";
}
.fa-chevron-up:before {
  content: "\F077";
}
.fa-chevron-down:before {
  content: "\F078";
}
.fa-retweet:before {
  content: "\F079";
}
.fa-shopping-cart:before {
  content: "\F07A";
}
.fa-folder:before {
  content: "\F07B";
}
.fa-folder-open:before {
  content: "\F07C";
}
.fa-arrows-v:before {
  content: "\F07D";
}
.fa-arrows-h:before {
  content: "\F07E";
}
.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\F080";
}
.fa-twitter-square:before {
  content: "\F081";
}
.fa-facebook-square:before {
  content: "\F082";
}
.fa-camera-retro:before {
  content: "\F083";
}
.fa-key:before {
  content: "\F084";
}
.fa-gears:before,
.fa-cogs:before {
  content: "\F085";
}
.fa-comments:before {
  content: "\F086";
}
.fa-thumbs-o-up:before {
  content: "\F087";
}
.fa-thumbs-o-down:before {
  content: "\F088";
}
.fa-star-half:before {
  content: "\F089";
}
.fa-heart-o:before {
  content: "\F08A";
}
.fa-sign-out:before {
  content: "\F08B";
}
.fa-linkedin-square:before {
  content: "\F08C";
}
.fa-thumb-tack:before {
  content: "\F08D";
}
.fa-external-link:before {
  content: "\F08E";
}
.fa-sign-in:before {
  content: "\F090";
}
.fa-trophy:before {
  content: "\F091";
}
.fa-github-square:before {
  content: "\F092";
}
.fa-upload:before {
  content: "\F093";
}
.fa-lemon-o:before {
  content: "\F094";
}
.fa-phone:before {
  content: "\F095";
}
.fa-square-o:before {
  content: "\F096";
}
.fa-bookmark-o:before {
  content: "\F097";
}
.fa-phone-square:before {
  content: "\F098";
}
.fa-twitter:before {
  content: "\F099";
}
.fa-facebook-f:before,
.fa-facebook:before {
  content: "\F09A";
}
.fa-github:before {
  content: "\F09B";
}
.fa-unlock:before {
  content: "\F09C";
}
.fa-credit-card:before {
  content: "\F09D";
}
.fa-feed:before,
.fa-rss:before {
  content: "\F09E";
}
.fa-hdd-o:before {
  content: "\F0A0";
}
.fa-bullhorn:before {
  content: "\F0A1";
}
.fa-bell:before {
  content: "\F0F3";
}
.fa-certificate:before {
  content: "\F0A3";
}
.fa-hand-o-right:before {
  content: "\F0A4";
}
.fa-hand-o-left:before {
  content: "\F0A5";
}
.fa-hand-o-up:before {
  content: "\F0A6";
}
.fa-hand-o-down:before {
  content: "\F0A7";
}
.fa-arrow-circle-left:before {
  content: "\F0A8";
}
.fa-arrow-circle-right:before {
  content: "\F0A9";
}
.fa-arrow-circle-up:before {
  content: "\F0AA";
}
.fa-arrow-circle-down:before {
  content: "\F0AB";
}
.fa-globe:before {
  content: "\F0AC";
}
.fa-wrench:before {
  content: "\F0AD";
}
.fa-tasks:before {
  content: "\F0AE";
}
.fa-filter:before {
  content: "\F0B0";
}
.fa-briefcase:before {
  content: "\F0B1";
}
.fa-arrows-alt:before {
  content: "\F0B2";
}
.fa-group:before,
.fa-users:before {
  content: "\F0C0";
}
.fa-chain:before,
.fa-link:before {
  content: "\F0C1";
}
.fa-cloud:before {
  content: "\F0C2";
}
.fa-flask:before {
  content: "\F0C3";
}
.fa-cut:before,
.fa-scissors:before {
  content: "\F0C4";
}
.fa-copy:before,
.fa-files-o:before {
  content: "\F0C5";
}
.fa-paperclip:before {
  content: "\F0C6";
}
.fa-save:before,
.fa-floppy-o:before {
  content: "\F0C7";
}
.fa-square:before {
  content: "\F0C8";
}
.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "\F0C9";
}
.fa-list-ul:before {
  content: "\F0CA";
}
.fa-list-ol:before {
  content: "\F0CB";
}
.fa-strikethrough:before {
  content: "\F0CC";
}
.fa-underline:before {
  content: "\F0CD";
}
.fa-table:before {
  content: "\F0CE";
}
.fa-magic:before {
  content: "\F0D0";
}
.fa-truck:before {
  content: "\F0D1";
}
.fa-pinterest:before {
  content: "\F0D2";
}
.fa-pinterest-square:before {
  content: "\F0D3";
}
.fa-google-plus-square:before {
  content: "\F0D4";
}
.fa-google-plus:before {
  content: "\F0D5";
}
.fa-money:before {
  content: "\F0D6";
}
.fa-caret-down:before {
  content: "\F0D7";
}
.fa-caret-up:before {
  content: "\F0D8";
}
.fa-caret-left:before {
  content: "\F0D9";
}
.fa-caret-right:before {
  content: "\F0DA";
}
.fa-columns:before {
  content: "\F0DB";
}
.fa-unsorted:before,
.fa-sort:before {
  content: "\F0DC";
}
.fa-sort-down:before,
.fa-sort-desc:before {
  content: "\F0DD";
}
.fa-sort-up:before,
.fa-sort-asc:before {
  content: "\F0DE";
}
.fa-envelope:before {
  content: "\F0E0";
}
.fa-linkedin:before {
  content: "\F0E1";
}
.fa-rotate-left:before,
.fa-undo:before {
  content: "\F0E2";
}
.fa-legal:before,
.fa-gavel:before {
  content: "\F0E3";
}
.fa-dashboard:before,
.fa-tachometer:before {
  content: "\F0E4";
}
.fa-comment-o:before {
  content: "\F0E5";
}
.fa-comments-o:before {
  content: "\F0E6";
}
.fa-flash:before,
.fa-bolt:before {
  content: "\F0E7";
}
.fa-sitemap:before {
  content: "\F0E8";
}
.fa-umbrella:before {
  content: "\F0E9";
}
.fa-paste:before,
.fa-clipboard:before {
  content: "\F0EA";
}
.fa-lightbulb-o:before {
  content: "\F0EB";
}
.fa-exchange:before {
  content: "\F0EC";
}
.fa-cloud-download:before {
  content: "\F0ED";
}
.fa-cloud-upload:before {
  content: "\F0EE";
}
.fa-user-md:before {
  content: "\F0F0";
}
.fa-stethoscope:before {
  content: "\F0F1";
}
.fa-suitcase:before {
  content: "\F0F2";
}
.fa-bell-o:before {
  content: "\F0A2";
}
.fa-coffee:before {
  content: "\F0F4";
}
.fa-cutlery:before {
  content: "\F0F5";
}
.fa-file-text-o:before {
  content: "\F0F6";
}
.fa-building-o:before {
  content: "\F0F7";
}
.fa-hospital-o:before {
  content: "\F0F8";
}
.fa-ambulance:before {
  content: "\F0F9";
}
.fa-medkit:before {
  content: "\F0FA";
}
.fa-fighter-jet:before {
  content: "\F0FB";
}
.fa-beer:before {
  content: "\F0FC";
}
.fa-h-square:before {
  content: "\F0FD";
}
.fa-plus-square:before {
  content: "\F0FE";
}
.fa-angle-double-left:before {
  content: "\F100";
}
.fa-angle-double-right:before {
  content: "\F101";
}
.fa-angle-double-up:before {
  content: "\F102";
}
.fa-angle-double-down:before {
  content: "\F103";
}
.fa-angle-left:before {
  content: "\F104";
}
.fa-angle-right:before {
  content: "\F105";
}
.fa-angle-up:before {
  content: "\F106";
}
.fa-angle-down:before {
  content: "\F107";
}
.fa-desktop:before {
  content: "\F108";
}
.fa-laptop:before {
  content: "\F109";
}
.fa-tablet:before {
  content: "\F10A";
}
.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\F10B";
}
.fa-circle-o:before {
  content: "\F10C";
}
.fa-quote-left:before {
  content: "\F10D";
}
.fa-quote-right:before {
  content: "\F10E";
}
.fa-spinner:before {
  content: "\F110";
}
.fa-circle:before {
  content: "\F111";
}
.fa-mail-reply:before,
.fa-reply:before {
  content: "\F112";
}
.fa-github-alt:before {
  content: "\F113";
}
.fa-folder-o:before {
  content: "\F114";
}
.fa-folder-open-o:before {
  content: "\F115";
}
.fa-smile-o:before {
  content: "\F118";
}
.fa-frown-o:before {
  content: "\F119";
}
.fa-meh-o:before {
  content: "\F11A";
}
.fa-gamepad:before {
  content: "\F11B";
}
.fa-keyboard-o:before {
  content: "\F11C";
}
.fa-flag-o:before {
  content: "\F11D";
}
.fa-flag-checkered:before {
  content: "\F11E";
}
.fa-terminal:before {
  content: "\F120";
}
.fa-code:before {
  content: "\F121";
}
.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\F122";
}
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\F123";
}
.fa-location-arrow:before {
  content: "\F124";
}
.fa-crop:before {
  content: "\F125";
}
.fa-code-fork:before {
  content: "\F126";
}
.fa-unlink:before,
.fa-chain-broken:before {
  content: "\F127";
}
.fa-question:before {
  content: "\F128";
}
.fa-info:before {
  content: "\F129";
}
.fa-exclamation:before {
  content: "\F12A";
}
.fa-superscript:before {
  content: "\F12B";
}
.fa-subscript:before {
  content: "\F12C";
}
.fa-eraser:before {
  content: "\F12D";
}
.fa-puzzle-piece:before {
  content: "\F12E";
}
.fa-microphone:before {
  content: "\F130";
}
.fa-microphone-slash:before {
  content: "\F131";
}
.fa-shield:before {
  content: "\F132";
}
.fa-calendar-o:before {
  content: "\F133";
}
.fa-fire-extinguisher:before {
  content: "\F134";
}
.fa-rocket:before {
  content: "\F135";
}
.fa-maxcdn:before {
  content: "\F136";
}
.fa-chevron-circle-left:before {
  content: "\F137";
}
.fa-chevron-circle-right:before {
  content: "\F138";
}
.fa-chevron-circle-up:before {
  content: "\F139";
}
.fa-chevron-circle-down:before {
  content: "\F13A";
}
.fa-html5:before {
  content: "\F13B";
}
.fa-css3:before {
  content: "\F13C";
}
.fa-anchor:before {
  content: "\F13D";
}
.fa-unlock-alt:before {
  content: "\F13E";
}
.fa-bullseye:before {
  content: "\F140";
}
.fa-ellipsis-h:before {
  content: "\F141";
}
.fa-ellipsis-v:before {
  content: "\F142";
}
.fa-rss-square:before {
  content: "\F143";
}
.fa-play-circle:before {
  content: "\F144";
}
.fa-ticket:before {
  content: "\F145";
}
.fa-minus-square:before {
  content: "\F146";
}
.fa-minus-square-o:before {
  content: "\F147";
}
.fa-level-up:before {
  content: "\F148";
}
.fa-level-down:before {
  content: "\F149";
}
.fa-check-square:before {
  content: "\F14A";
}
.fa-pencil-square:before {
  content: "\F14B";
}
.fa-external-link-square:before {
  content: "\F14C";
}
.fa-share-square:before {
  content: "\F14D";
}
.fa-compass:before {
  content: "\F14E";
}
.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\F150";
}
.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\F151";
}
.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\F152";
}
.fa-euro:before,
.fa-eur:before {
  content: "\F153";
}
.fa-gbp:before {
  content: "\F154";
}
.fa-dollar:before,
.fa-usd:before {
  content: "\F155";
}
.fa-rupee:before,
.fa-inr:before {
  content: "\F156";
}
.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\F157";
}
.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\F158";
}
.fa-won:before,
.fa-krw:before {
  content: "\F159";
}
.fa-bitcoin:before,
.fa-btc:before {
  content: "\F15A";
}
.fa-file:before {
  content: "\F15B";
}
.fa-file-text:before {
  content: "\F15C";
}
.fa-sort-alpha-asc:before {
  content: "\F15D";
}
.fa-sort-alpha-desc:before {
  content: "\F15E";
}
.fa-sort-amount-asc:before {
  content: "\F160";
}
.fa-sort-amount-desc:before {
  content: "\F161";
}
.fa-sort-numeric-asc:before {
  content: "\F162";
}
.fa-sort-numeric-desc:before {
  content: "\F163";
}
.fa-thumbs-up:before {
  content: "\F164";
}
.fa-thumbs-down:before {
  content: "\F165";
}
.fa-youtube-square:before {
  content: "\F166";
}
.fa-youtube:before {
  content: "\F167";
}
.fa-xing:before {
  content: "\F168";
}
.fa-xing-square:before {
  content: "\F169";
}
.fa-youtube-play:before {
  content: "\F16A";
}
.fa-dropbox:before {
  content: "\F16B";
}
.fa-stack-overflow:before {
  content: "\F16C";
}
.fa-instagram:before {
  content: "\F16D";
}
.fa-flickr:before {
  content: "\F16E";
}
.fa-adn:before {
  content: "\F170";
}
.fa-bitbucket:before {
  content: "\F171";
}
.fa-bitbucket-square:before {
  content: "\F172";
}
.fa-tumblr:before {
  content: "\F173";
}
.fa-tumblr-square:before {
  content: "\F174";
}
.fa-long-arrow-down:before {
  content: "\F175";
}
.fa-long-arrow-up:before {
  content: "\F176";
}
.fa-long-arrow-left:before {
  content: "\F177";
}
.fa-long-arrow-right:before {
  content: "\F178";
}
.fa-apple:before {
  content: "\F179";
}
.fa-windows:before {
  content: "\F17A";
}
.fa-android:before {
  content: "\F17B";
}
.fa-linux:before {
  content: "\F17C";
}
.fa-dribbble:before {
  content: "\F17D";
}
.fa-skype:before {
  content: "\F17E";
}
.fa-foursquare:before {
  content: "\F180";
}
.fa-trello:before {
  content: "\F181";
}
.fa-female:before {
  content: "\F182";
}
.fa-male:before {
  content: "\F183";
}
.fa-gittip:before,
.fa-gratipay:before {
  content: "\F184";
}
.fa-sun-o:before {
  content: "\F185";
}
.fa-moon-o:before {
  content: "\F186";
}
.fa-archive:before {
  content: "\F187";
}
.fa-bug:before {
  content: "\F188";
}
.fa-vk:before {
  content: "\F189";
}
.fa-weibo:before {
  content: "\F18A";
}
.fa-renren:before {
  content: "\F18B";
}
.fa-pagelines:before {
  content: "\F18C";
}
.fa-stack-exchange:before {
  content: "\F18D";
}
.fa-arrow-circle-o-right:before {
  content: "\F18E";
}
.fa-arrow-circle-o-left:before {
  content: "\F190";
}
.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\F191";
}
.fa-dot-circle-o:before {
  content: "\F192";
}
.fa-wheelchair:before {
  content: "\F193";
}
.fa-vimeo-square:before {
  content: "\F194";
}
.fa-turkish-lira:before,
.fa-try:before {
  content: "\F195";
}
.fa-plus-square-o:before {
  content: "\F196";
}
.fa-space-shuttle:before {
  content: "\F197";
}
.fa-slack:before {
  content: "\F198";
}
.fa-envelope-square:before {
  content: "\F199";
}
.fa-wordpress:before {
  content: "\F19A";
}
.fa-openid:before {
  content: "\F19B";
}
.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "\F19C";
}
.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "\F19D";
}
.fa-yahoo:before {
  content: "\F19E";
}
.fa-google:before {
  content: "\F1A0";
}
.fa-reddit:before {
  content: "\F1A1";
}
.fa-reddit-square:before {
  content: "\F1A2";
}
.fa-stumbleupon-circle:before {
  content: "\F1A3";
}
.fa-stumbleupon:before {
  content: "\F1A4";
}
.fa-delicious:before {
  content: "\F1A5";
}
.fa-digg:before {
  content: "\F1A6";
}
.fa-pied-piper:before {
  content: "\F1A7";
}
.fa-pied-piper-alt:before {
  content: "\F1A8";
}
.fa-drupal:before {
  content: "\F1A9";
}
.fa-joomla:before {
  content: "\F1AA";
}
.fa-language:before {
  content: "\F1AB";
}
.fa-fax:before {
  content: "\F1AC";
}
.fa-building:before {
  content: "\F1AD";
}
.fa-child:before {
  content: "\F1AE";
}
.fa-paw:before {
  content: "\F1B0";
}
.fa-spoon:before {
  content: "\F1B1";
}
.fa-cube:before {
  content: "\F1B2";
}
.fa-cubes:before {
  content: "\F1B3";
}
.fa-behance:before {
  content: "\F1B4";
}
.fa-behance-square:before {
  content: "\F1B5";
}
.fa-steam:before {
  content: "\F1B6";
}
.fa-steam-square:before {
  content: "\F1B7";
}
.fa-recycle:before {
  content: "\F1B8";
}
.fa-automobile:before,
.fa-car:before {
  content: "\F1B9";
}
.fa-cab:before,
.fa-taxi:before {
  content: "\F1BA";
}
.fa-tree:before {
  content: "\F1BB";
}
.fa-spotify:before {
  content: "\F1BC";
}
.fa-deviantart:before {
  content: "\F1BD";
}
.fa-soundcloud:before {
  content: "\F1BE";
}
.fa-database:before {
  content: "\F1C0";
}
.fa-file-pdf-o:before {
  content: "\F1C1";
}
.fa-file-word-o:before {
  content: "\F1C2";
}
.fa-file-excel-o:before {
  content: "\F1C3";
}
.fa-file-powerpoint-o:before {
  content: "\F1C4";
}
.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "\F1C5";
}
.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "\F1C6";
}
.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "\F1C7";
}
.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\F1C8";
}
.fa-file-code-o:before {
  content: "\F1C9";
}
.fa-vine:before {
  content: "\F1CA";
}
.fa-codepen:before {
  content: "\F1CB";
}
.fa-jsfiddle:before {
  content: "\F1CC";
}
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "\F1CD";
}
.fa-circle-o-notch:before {
  content: "\F1CE";
}
.fa-ra:before,
.fa-rebel:before {
  content: "\F1D0";
}
.fa-ge:before,
.fa-empire:before {
  content: "\F1D1";
}
.fa-git-square:before {
  content: "\F1D2";
}
.fa-git:before {
  content: "\F1D3";
}
.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: "\F1D4";
}
.fa-tencent-weibo:before {
  content: "\F1D5";
}
.fa-qq:before {
  content: "\F1D6";
}
.fa-wechat:before,
.fa-weixin:before {
  content: "\F1D7";
}
.fa-send:before,
.fa-paper-plane:before {
  content: "\F1D8";
}
.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "\F1D9";
}
.fa-history:before {
  content: "\F1DA";
}
.fa-circle-thin:before {
  content: "\F1DB";
}
.fa-header:before {
  content: "\F1DC";
}
.fa-paragraph:before {
  content: "\F1DD";
}
.fa-sliders:before {
  content: "\F1DE";
}
.fa-share-alt:before {
  content: "\F1E0";
}
.fa-share-alt-square:before {
  content: "\F1E1";
}
.fa-bomb:before {
  content: "\F1E2";
}
.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "\F1E3";
}
.fa-tty:before {
  content: "\F1E4";
}
.fa-binoculars:before {
  content: "\F1E5";
}
.fa-plug:before {
  content: "\F1E6";
}
.fa-slideshare:before {
  content: "\F1E7";
}
.fa-twitch:before {
  content: "\F1E8";
}
.fa-yelp:before {
  content: "\F1E9";
}
.fa-newspaper-o:before {
  content: "\F1EA";
}
.fa-wifi:before {
  content: "\F1EB";
}
.fa-calculator:before {
  content: "\F1EC";
}
.fa-paypal:before {
  content: "\F1ED";
}
.fa-google-wallet:before {
  content: "\F1EE";
}
.fa-cc-visa:before {
  content: "\F1F0";
}
.fa-cc-mastercard:before {
  content: "\F1F1";
}
.fa-cc-discover:before {
  content: "\F1F2";
}
.fa-cc-amex:before {
  content: "\F1F3";
}
.fa-cc-paypal:before {
  content: "\F1F4";
}
.fa-cc-stripe:before {
  content: "\F1F5";
}
.fa-bell-slash:before {
  content: "\F1F6";
}
.fa-bell-slash-o:before {
  content: "\F1F7";
}
.fa-trash:before {
  content: "\F1F8";
}
.fa-copyright:before {
  content: "\F1F9";
}
.fa-at:before {
  content: "\F1FA";
}
.fa-eyedropper:before {
  content: "\F1FB";
}
.fa-paint-brush:before {
  content: "\F1FC";
}
.fa-birthday-cake:before {
  content: "\F1FD";
}
.fa-area-chart:before {
  content: "\F1FE";
}
.fa-pie-chart:before {
  content: "\F200";
}
.fa-line-chart:before {
  content: "\F201";
}
.fa-lastfm:before {
  content: "\F202";
}
.fa-lastfm-square:before {
  content: "\F203";
}
.fa-toggle-off:before {
  content: "\F204";
}
.fa-toggle-on:before {
  content: "\F205";
}
.fa-bicycle:before {
  content: "\F206";
}
.fa-bus:before {
  content: "\F207";
}
.fa-ioxhost:before {
  content: "\F208";
}
.fa-angellist:before {
  content: "\F209";
}
.fa-cc:before {
  content: "\F20A";
}
.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "\F20B";
}
.fa-meanpath:before {
  content: "\F20C";
}
.fa-buysellads:before {
  content: "\F20D";
}
.fa-connectdevelop:before {
  content: "\F20E";
}
.fa-dashcube:before {
  content: "\F210";
}
.fa-forumbee:before {
  content: "\F211";
}
.fa-leanpub:before {
  content: "\F212";
}
.fa-sellsy:before {
  content: "\F213";
}
.fa-shirtsinbulk:before {
  content: "\F214";
}
.fa-simplybuilt:before {
  content: "\F215";
}
.fa-skyatlas:before {
  content: "\F216";
}
.fa-cart-plus:before {
  content: "\F217";
}
.fa-cart-arrow-down:before {
  content: "\F218";
}
.fa-diamond:before {
  content: "\F219";
}
.fa-ship:before {
  content: "\F21A";
}
.fa-user-secret:before {
  content: "\F21B";
}
.fa-motorcycle:before {
  content: "\F21C";
}
.fa-street-view:before {
  content: "\F21D";
}
.fa-heartbeat:before {
  content: "\F21E";
}
.fa-venus:before {
  content: "\F221";
}
.fa-mars:before {
  content: "\F222";
}
.fa-mercury:before {
  content: "\F223";
}
.fa-intersex:before,
.fa-transgender:before {
  content: "\F224";
}
.fa-transgender-alt:before {
  content: "\F225";
}
.fa-venus-double:before {
  content: "\F226";
}
.fa-mars-double:before {
  content: "\F227";
}
.fa-venus-mars:before {
  content: "\F228";
}
.fa-mars-stroke:before {
  content: "\F229";
}
.fa-mars-stroke-v:before {
  content: "\F22A";
}
.fa-mars-stroke-h:before {
  content: "\F22B";
}
.fa-neuter:before {
  content: "\F22C";
}
.fa-genderless:before {
  content: "\F22D";
}
.fa-facebook-official:before {
  content: "\F230";
}
.fa-pinterest-p:before {
  content: "\F231";
}
.fa-whatsapp:before {
  content: "\F232";
}
.fa-server:before {
  content: "\F233";
}
.fa-user-plus:before {
  content: "\F234";
}
.fa-user-times:before {
  content: "\F235";
}
.fa-hotel:before,
.fa-bed:before {
  content: "\F236";
}
.fa-viacoin:before {
  content: "\F237";
}
.fa-train:before {
  content: "\F238";
}
.fa-subway:before {
  content: "\F239";
}
.fa-medium:before {
  content: "\F23A";
}
.fa-yc:before,
.fa-y-combinator:before {
  content: "\F23B";
}
.fa-optin-monster:before {
  content: "\F23C";
}
.fa-opencart:before {
  content: "\F23D";
}
.fa-expeditedssl:before {
  content: "\F23E";
}
.fa-battery-4:before,
.fa-battery-full:before {
  content: "\F240";
}
.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\F241";
}
.fa-battery-2:before,
.fa-battery-half:before {
  content: "\F242";
}
.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\F243";
}
.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\F244";
}
.fa-mouse-pointer:before {
  content: "\F245";
}
.fa-i-cursor:before {
  content: "\F246";
}
.fa-object-group:before {
  content: "\F247";
}
.fa-object-ungroup:before {
  content: "\F248";
}
.fa-sticky-note:before {
  content: "\F249";
}
.fa-sticky-note-o:before {
  content: "\F24A";
}
.fa-cc-jcb:before {
  content: "\F24B";
}
.fa-cc-diners-club:before {
  content: "\F24C";
}
.fa-clone:before {
  content: "\F24D";
}
.fa-balance-scale:before {
  content: "\F24E";
}
.fa-hourglass-o:before {
  content: "\F250";
}
.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\F251";
}
.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\F252";
}
.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\F253";
}
.fa-hourglass:before {
  content: "\F254";
}
.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\F255";
}
.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "\F256";
}
.fa-hand-scissors-o:before {
  content: "\F257";
}
.fa-hand-lizard-o:before {
  content: "\F258";
}
.fa-hand-spock-o:before {
  content: "\F259";
}
.fa-hand-pointer-o:before {
  content: "\F25A";
}
.fa-hand-peace-o:before {
  content: "\F25B";
}
.fa-trademark:before {
  content: "\F25C";
}
.fa-registered:before {
  content: "\F25D";
}
.fa-creative-commons:before {
  content: "\F25E";
}
.fa-gg:before {
  content: "\F260";
}
.fa-gg-circle:before {
  content: "\F261";
}
.fa-tripadvisor:before {
  content: "\F262";
}
.fa-odnoklassniki:before {
  content: "\F263";
}
.fa-odnoklassniki-square:before {
  content: "\F264";
}
.fa-get-pocket:before {
  content: "\F265";
}
.fa-wikipedia-w:before {
  content: "\F266";
}
.fa-safari:before {
  content: "\F267";
}
.fa-chrome:before {
  content: "\F268";
}
.fa-firefox:before {
  content: "\F269";
}
.fa-opera:before {
  content: "\F26A";
}
.fa-internet-explorer:before {
  content: "\F26B";
}
.fa-tv:before,
.fa-television:before {
  content: "\F26C";
}
.fa-contao:before {
  content: "\F26D";
}
.fa-500px:before {
  content: "\F26E";
}
.fa-amazon:before {
  content: "\F270";
}
.fa-calendar-plus-o:before {
  content: "\F271";
}
.fa-calendar-minus-o:before {
  content: "\F272";
}
.fa-calendar-times-o:before {
  content: "\F273";
}
.fa-calendar-check-o:before {
  content: "\F274";
}
.fa-industry:before {
  content: "\F275";
}
.fa-map-pin:before {
  content: "\F276";
}
.fa-map-signs:before {
  content: "\F277";
}
.fa-map-o:before {
  content: "\F278";
}
.fa-map:before {
  content: "\F279";
}
.fa-commenting:before {
  content: "\F27A";
}
.fa-commenting-o:before {
  content: "\F27B";
}
.fa-houzz:before {
  content: "\F27C";
}
.fa-vimeo:before {
  content: "\F27D";
}
.fa-black-tie:before {
  content: "\F27E";
}
.fa-fonticons:before {
  content: "\F280";
}
.fa-reddit-alien:before {
  content: "\F281";
}
.fa-edge:before {
  content: "\F282";
}
.fa-credit-card-alt:before {
  content: "\F283";
}
.fa-codiepie:before {
  content: "\F284";
}
.fa-modx:before {
  content: "\F285";
}
.fa-fort-awesome:before {
  content: "\F286";
}
.fa-usb:before {
  content: "\F287";
}
.fa-product-hunt:before {
  content: "\F288";
}
.fa-mixcloud:before {
  content: "\F289";
}
.fa-scribd:before {
  content: "\F28A";
}
.fa-pause-circle:before {
  content: "\F28B";
}
.fa-pause-circle-o:before {
  content: "\F28C";
}
.fa-stop-circle:before {
  content: "\F28D";
}
.fa-stop-circle-o:before {
  content: "\F28E";
}
.fa-shopping-bag:before {
  content: "\F290";
}
.fa-shopping-basket:before {
  content: "\F291";
}
.fa-hashtag:before {
  content: "\F292";
}
.fa-bluetooth:before {
  content: "\F293";
}
.fa-bluetooth-b:before {
  content: "\F294";
}
.fa-percent:before {
  content: "\F295";
}
