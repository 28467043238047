.compose-mode .vc_element-container > div.vc_container-block:first-of-type {
	margin-top: 31px;
}
.vc_editor .no-top-margin:first-child {
    padding-top: 110px;
}


.wpb_row, .wpb_content_element, ul.wpb_thumbnails-fluid > li, .last_toggle_el_margin, .wpb_button {
	margin-bottom: 1.1em;
}

.wpb_row:last-of-type {
	margin-bottom: 0;
}

@media (max-width: 1199px) {
	.wpb_row {
		margin-top: -1.421em;
	}
	.wpb_row > * {
		margin-top: 1.421em;
	}
}

.gem-alert-box .wpb_text_column p:first-child,
.gem-alert-box .wpb_text_column *:first-child,
.gem-icon-with-text .wpb_text_column p:first-child,
.gem-icon-with-text .wpb_text_column *:first-child {
	margin-top: 0;
}

.vc_gem_portfolio_slider > *,
.vc_gem_portfolio > *,
.vc_gem_product_grid > *,
.vc_gem_product_slider > * {
	display: none;
}
.vc_gem_portfolio_slider,
.vc_gem_portfolio {
	height: 300px;
	background: #f0f4f7 url('https://cms.concrad.com/wp-content/themes/thegem/images/admin-images/portfolio-dummy.png') no-repeat 50% 50%;
}
.vc_gem_product_grid,
.vc_gem_product_slider {
	height: 300px;
	background: #f0f4f7 url('https://cms.concrad.com/wp-content/themes/thegem/images/admin-images/products-dummy.png') no-repeat 50% 50%;
}
.vc_gem_testimonials > * {
	display: none;
}
.vc_gem_testimonials {
	height: 300px;
	background: #f0f4f7 url('https://cms.concrad.com/wp-content/themes/thegem/images/admin-images/testimonials-dummy.png') no-repeat 50% 50%;
}
.vc_gem_countdown > * {
	display: none;
}
.vc_gem_countdown {
	height: 200px;
	background: #f0f4f7 url('https://cms.concrad.com/wp-content/themes/thegem/images/admin-images/countdown-dummy.png') no-repeat 50% 50%;
}
.vc_gem_news > * {
	display: none;
}
.vc_gem_news {
	height: 300px;
	background: #f0f4f7 url('https://cms.concrad.com/wp-content/themes/thegem/images/admin-images/blog-dummy.png') no-repeat 50% 50%;
}
.vc_gem_news_grid {
	height: 300px;
	background: #f0f4f7 url('https://cms.concrad.com/wp-content/themes/thegem/images/admin-images/blog-extended-dummy.png') no-repeat 50% 50%;
}
.vc_gem_featured_posts_slider {
	height: 300px;
	background: #f0f4f7 url('https://cms.concrad.com/wp-content/themes/thegem/images/admin-images/featured-posts-slider-dummy.png') no-repeat 50% 40%;
}
.vc_gem_gallery > * {
	display: none;
}
.vc_gem_gallery {
	height: 300px;
	background: #f0f4f7 url('https://cms.concrad.com/wp-content/themes/thegem/images/admin-images/gallery-dummy.png') no-repeat 50% 50%;
}
.vc_gem_diagram > * {
	display: none;
}
.vc_gem_diagram {
	height: 300px;
	background: #f0f4f7 url('https://cms.concrad.com/wp-content/themes/thegem/images/admin-images/diagram-dummy.png') no-repeat 50% 50%;
}
.vc_gem_clients > * {
	display: none;
}
.vc_gem_clients {
	height: 300px;
	background: #f0f4f7 url('https://cms.concrad.com/wp-content/themes/thegem/images/admin-images/clients-dummy.png') no-repeat 50% 50%;
}
.vc_gem_counter > * {
	display: none;
}
.vc_gem_counter {
	width: 25%;
	padding: 0 21px;
}
.gem-counter-style-vertical .vc_gem_counter {
	width: 100%;
	padding: 0;
}
.vc_gem_counter > .counter-shortcode-dummy {
	display: block;
	height: 300px;
	background: #f0f4f7 url('https://cms.concrad.com/wp-content/themes/thegem/images/admin-images/counter-dummy.png') no-repeat 50% 50%;
}
.vc_gem_instagram_gallery > * {
	display: none;
}
.vc_gem_instagram_gallery {
	height: 300px;
	background: #f0f4f7 url('https://cms.concrad.com/wp-content/themes/thegem/images/admin-images/instagram-dummy.png') no-repeat 50% 50%;
}

.vc_gem_divider {
	padding: 3px 0;
	background: -webkit-repeating-linear-gradient(-45deg, #f1f5f8 0px, #f1f5f8 1px, transparent 1px, transparent 10px);
	background: -moz-repeating-linear-gradient(-45deg, #f1f5f8 0px, #f1f5f8 1px, transparent 1px, transparent 10px);
	background: -o-repeating-linear-gradient(-45deg, #f1f5f8 0px, #f1f5f8 1px, transparent 1px, transparent 10px);
	background: -ms-repeating-linear-gradient(-45deg, #f1f5f8 0px, #f1f5f8 1px, transparent 1px, transparent 10px);
	background: repeating-linear-gradient(-45deg, #f1f5f8 0px, #f1f5f8 1px, transparent 1px, transparent 10px);
}
.vc_gem_dropcap {
	float: left;
	z-index: 1;
}

.compose-mode .vc_element .vc_element-container,
.compose-mode .vc_element vc_container-block {
	padding-bottom: 30px;
}

.compose-mode .vc_gem_pricing_column.vc_element {
	display: inline-block;
}

.vc_gem_pricing_column .pricing-column-wrapper {
	width: 100%;
}

.compose-mode .fullwidth-block-inner .container {
	max-width: 100%;
}
/*
.compose-mode .vc_container-block .fullwidth-block {
	margin-left: -21px;
	margin-right: -21px;
}*/

.vc_row.vc_row-o-full-height.thegem-fix-full-height {
	min-height: 100vh !important;
}