.flex-container a:active,
.flex-container a:focus,
.flexslider a:active,
.flexslider a:focus {
    outline: 0;
}
.flex-control-nav,
.flex-direction-nav,
.slides {
    margin: 0;
    padding: 0;
    list-style: none;
}
@font-face {
    font-family: flexslider-icon;
    src: url(https://cms.concrad.com/wp-content/plugins/js_composer/assets/lib/bower/flexslider/fonts/flexslider-icon.eot);
    src: url(https://cms.concrad.com/wp-content/plugins/js_composer/assets/lib/bower/flexslider/fonts/flexslider-icon.eot?#iefix) format("embedded-opentype"), url(https://cms.concrad.com/wp-content/plugins/js_composer/assets/lib/bower/flexslider/fonts/flexslider-icon.woff) format("woff"), url(https://cms.concrad.com/wp-content/plugins/js_composer/assets/lib/bower/flexslider/fonts/flexslider-icon.ttf) format("truetype"), url(https://cms.concrad.com/wp-content/plugins/js_composer/assets/lib/bower/flexslider/fonts/flexslider-icon.svg#flexslider-icon) format("svg");
    font-weight: 400;
    font-style: normal;
}
.flexslider {
    margin: 0;
    padding: 0;
}
.flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden;
}
.flexslider .slides img {
    width: 100%;
    display: block;
}
.flex-pauseplay span {
    text-transform: capitalize;
}
.slides:after {
    content: " ";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}
html[xmlns] .slides {
    display: block;
}
* html .slides {
    height: 1%;
}
.no-js .slides > li:first-child {
    display: block;
}
.flexslider {
    margin: 0 0 60px;
    background: #fff;
    border: 4px solid #fff;
    position: relative;
    border-radius: 4px;
    -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    zoom: 1;
}
.flex-viewport {
    max-height: 2000px;
    transition: all 1s ease;
}
.loading .flex-viewport {
    max-height: 300px;
}
.flexslider .slides {
    zoom: 1;
}
.carousel li {
    margin-right: 5px;
}
.flex-direction-nav a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    transition: all 0.3s ease;
}
.flex-direction-nav .flex-prev {
    left: -50px;
}
.flex-direction-nav .flex-next {
    right: -50px;
    text-align: right;
}
.flexslider:hover .flex-prev {
    opacity: 0.7;
    left: 10px;
}
.flexslider:hover .flex-next {
    opacity: 0.7;
    right: 10px;
}
.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
    opacity: 1;
}
.flex-direction-nav .flex-disabled {
    opacity: 0 !important;
    cursor: default;
}
.flex-direction-nav a:before {
    font-family: flexslider-icon;
    font-size: 40px;
    display: inline-block;
    content: "\F001";
}
.flex-direction-nav a.flex-next:before {
    content: "\F002";
}
.flex-pauseplay a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    opacity: 0.8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: #000;
}
.flex-pauseplay a:before {
    font-family: flexslider-icon;
    font-size: 20px;
    display: inline-block;
    content: "\F004";
}
.flex-pauseplay a:hover {
    opacity: 1;
}
.flex-pauseplay a.flex-play:before {
    content: "\F003";
}
.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -40px;
    text-align: center;
}
.flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
}
.flex-control-paging li a {
    width: 11px;
    height: 11px;
    display: block;
    background: #666;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-indent: -9999px;
    border-radius: 20px;
    -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}
.flex-control-paging li a:hover {
    background: #333;
    background: rgba(0, 0, 0, 0.7);
}
.flex-control-paging li a.flex-active {
    background: #000;
    background: rgba(0, 0, 0, 0.9);
    cursor: default;
}
.flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
}
.flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0;
}
.flex-control-thumbs img {
    width: 100%;
    display: block;
    opacity: 0.7;
    cursor: pointer;
}
.flex-control-thumbs img:hover {
    opacity: 1;
}
.flex-control-thumbs .flex-active {
    opacity: 1;
    cursor: default;
}
@media screen and (max-width: 860px) {
    .flex-direction-nav .flex-prev {
        opacity: 1;
        left: 10px;
    }
    .flex-direction-nav .flex-next {
        opacity: 1;
        right: 10px;
    }
}
