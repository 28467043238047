/* Body Styles */
/*
body { 
    margin: 0px; 
    padding: 0px; 
    font-family: 'Open Sans', sans-serif; 
    background-color: #fff;  
  }
  
*/
.author {
  position: fixed;
  bottom: 15px;
  right: 15px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #999; }

.author a {
  color: #777;
  text-decoration: none; }

.author a:hover {
  color: blue; }

/*
  header.dark blockquote { color:#fff; }
  header.light blockquote { color:#000; }
  */
blockquote {
  max-width: 1000px;
  margin: 0 auto;
  font-size: 16px;
  border-left: 0px;
  padding: 20px; }

blockquote h2 {
  padding-right: 40px;
  margin: 0px; }

/*
  header.dark blockquote a {color: orange; text-decoration: underline;}
  header.light blockquote a {text-decoration: underline;}
  header { min-height: 450px; }
  header.dark { background-color: #444; }
  header.light { background-color: #fff; }
  */
/* Navigation Styles */
nav {
  position: relative; }

/*
  header.dark nav { background-color:rgba(255,255,255,0.5); }
  header.light nav { background-color:rgba(0,0,0,0.5); }
  */
ul.main-nav {
  list-style-type: none;
  padding: 0px;
  /*    font-size: 0px; */
  width: 100%;
  margin: 0 auto; }

ul.main-nav > li {
  display: inline-block;
  padding: 0; }

ul.main-nav > li > a {
  display: block;
  padding: 10px 20px;
  position: relative;
  color: var(--menu-font-color, #ffffff);
  font-size: var(--menu-font-size, 20px);
  font-weight: var(--menu-font-weight, 800);
  box-sizing: border-box;
  text-transform: var(--menu-font-text-transform, "uppercase");
  height: 100%; }

.sub-menu-link > a {
  display: block;
  padding: 1px 20px;
  position: relative;
  color: var(--menu-font-color, #ffffff);
  font-size: var(--menu-font-size, 20px);
  font-weight: var(--menu-font-weight, 800);
  box-sizing: border-box;
  text-transform: var(--menu-font-text-transform, "uppercase"); }

.mega-menu-1 {
  font-size: 14px; }

.mega-menu-1:hover {
  font-size: 16px !important; }

ul.main-nav > li:hover {
  background-color: var(--menu-background-color-hover, #000000);
  color: var(--menu-font-color-hover, #000000); }

ul.main-nav > li:hover > a {
  color: var(--menu-font-color-hover, #000000);
  font-weight: var(--menu-font-weight-hover, 900);
  text-decoration: none; }

.sub-menu-link > a:hover {
  font-weight: var(--menu-font-weight-hover, 900);
  text-decoration: none; }

ul.main-nav > li ul.sub-menu-lists {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: block; }

ul.main-nav > li ul.sub-menu-lists > li {
  padding: 2px 0; }

ul.main-nav > li ul.sub-menu-lists > li > a {
  font-size: 14px; }

/* MENU TOP DE HASTA ARRIBA*/
ul.main-nav-top {
  list-style-type: none;
  padding: 0px;
  /*    font-size: 0px; */
  width: 100%;
  margin: 0 auto; }

ul.main-nav-top > li {
  display: inline-block;
  padding: 0; }

ul.main-nav-top > li > a {
  display: block;
  padding: 10px 20px;
  position: relative;
  color: var(--menu-font-color, #ffffff);
  font-size: var(--menu-font-size, 20px);
  font-weight: var(--menu-font-weight, 800);
  box-sizing: border-box;
  text-transform: var(--menu-font-text-transform, "uppercase");
  height: 100%; }

ul.main-nav-top > li:hover {
  background-color: var(--menu-background-color-hover, #000000);
  color: var(--menu-font-color-hover, #000000); }

ul.main-nav-top > li:hover > a {
  color: var(--menu-font-color-hover, #000000);
  font-weight: var(--menu-font-weight-hover, 900);
  text-decoration: none; }

ul.main-nav-top > li ul.sub-menu-lists {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: block; }

ul.main-nav-top > li ul.sub-menu-lists > li {
  padding: 2px 0; }

ul.main-nav-top > li ul.sub-menu-lists > li > a {
  font-size: 14px; }

.hide-on-mobile {
  display: inline-block; }

.bar-separator {
  border-width: 0px;
  border-right-width: 2px;
  border-style: solid;
  padding: 0px 20px !important; }

@media (max-width: 480px) {
  .hide-on-mobile {
    display: none !important; }
  .bar-separator {
    border-width: 0px;
    padding-right: 5px !important; } }

/* FIN  */
.sub-menu-nivel-0 .sub-menu-block {
  padding: 0px 0px 10px 0px; }

.sub-menu-nivel-1 > .sub-menu-link > a {
  padding: 0px 10px !important;
  font-size: 12px !important; }

.ic {
  position: fixed;
  cursor: pointer;
  display: inline-block;
  left: 13px;
  width: 32px;
  height: 24px;
  text-align: center;
  top: 0px;
  outline: none; }

.ic.close {
  opacity: 0;
  font-size: 0px;
  font-weight: 300;
  color: var(--menu-font-color, #ffffff);
  top: 8px;
  padding-top: 2px;
  height: 40px;
  display: block;
  outline: none; }

/* Menu Icons for Devices*/
.ic.menu {
  top: 13px;
  z-index: 20; }

.ic.menu .line {
  height: 4px;
  width: 100%;
  display: block;
  margin-bottom: 6px; }

.ic.menu .line-last-child {
  margin-bottom: 0px; }

.sub-menu-head {
  margin: 10px 0; }

.banners-area {
  margin-top: 20px;
  padding-top: 15px; }

@media only screen and (max-width: 768px) {
  ul.main-nav > li > a {
    padding: 10px 20px 0px; }
  .sub-menu-head {
    color: orange; }
  .ic.menu {
    display: block; }
  /*
    header.dark .ic.menu .line { background-color: #fff; } 
    header.light
    */
  .ic.menu .line {
    background-color: var(--menu-font-color, #ffffff); }
  .ic.menu .line {
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center; }
  .ic.menu:focus .line {
    background-color: #fff !important; }
  .ic.menu:focus .line:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .ic.menu:focus .line:nth-child(2) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-top: -10px; }
  .ic.menu:focus .line:nth-child(3) {
    transform: translateY(15px);
    opacity: 0; }
  .ic.menu:focus {
    outline: none; }
  .ic.menu:focus ~ .ic.close {
    opacity: 1;
    z-index: 21;
    outline: none; }
  /*
    
    .ic.menu:focus ~ .ic.close { opacity: 1.0; z-index : 21;  }
    .ic.close:focus { opacity: 0; }
    */
  .ic.menu:hover,
  .ic.menu:focus {
    opacity: 1; }
  nav {
    background-color: transparent; }
  /* Main Menu for Handheld Devices  */
  ul.main-nav {
    z-index: 200;
    padding: 50px 0;
    position: fixed;
    left: 0px;
    top: 53px;
    width: 0px;
    background-color: var(--color-principal, #000000);
    height: 100%;
    overflow: auto;
    /*CSS animation applied : Slide from Right*/
    -webkit-transition-property: background, width;
    -moz-transition-property: background, width;
    -o-transition-property: background, width;
    transition-property: background, width;
    -webkit-transition-duration: 0.6s;
    -moz-transition-duration: 0.6s;
    -o-transition-duration: 0.6s;
    transition-duration: 0.6s; }
  .ic.menu:focus ~ .main-nav {
    width: 100%;
    background-color: var(--color-principal, #000000);
    /*rgba(0,0,0,1); */ }
  ul.main-nav > * {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    opacity: 0; }
  .ic.menu:focus ~ .main-nav > * {
    opacity: 1; }
  ul.main-nav > li > a:after {
    display: none; }
  ul.main-nav > li:first-child {
    border-radius: 0px; }
  ul.main-nav > li {
    display: block;
    border-bottom: 1px solid #444; }
  ul.main-nav > li > a {
    font-weight: 600; }
  ul.main-nav > li ul.sub-menu-lists > li a {
    color: var(--menu-font-color, #ffffff);
    font-size: 14px; }
  ul.main-nav > li ul.sub-menu-lists > li a:hover {
    color: var(--menu-font-color-hover, #000000);
    font-size: 14px; }
  .sub-menu-head {
    font-size: 16px; }
  ul.main-nav > li:hover {
    background-color: transparent; }
  ul.main-nav > li:hover > a {
    color: var(--menu-font-color-hover, #000000);
    text-decoration: none;
    font-weight: 600; }
  .ic.menu:focus ~ ul.main-nav > li > div.sub-menu-block {
    border-left: 0px solid #ccc;
    border-right: 0px solid #ccc;
    border-bottom: 0px solid #ccc;
    position: relative;
    visibility: visible;
    opacity: 1.0; }
  .sub-menu-block {
    padding: 0 30px; }
  .banners-area {
    padding-bottom: 0px; }
  .banners-area div {
    margin-bottom: 15px; }
  .banners-area {
    border-top: 1px solid #444; } }

@media only screen and (min-width: 769px) {
  .ic.menu {
    display: none; }
  /* Main Menu for Desktop Devices  */
  ul.main-nav {
    display: block;
    position: relative;
    text-align: center; }
  .sub-menu-block {
    padding: 15px; }
  /* Sub Menu */
  ul.main-nav > li > div.sub-menu-block {
    visibility: hidden;
    background-color: #f9f9f9;
    position: absolute;
    margin-top: 0px;
    width: 100%;
    color: #333;
    left: 0;
    box-sizing: border-box;
    z-index: 3;
    font-size: 16px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    opacity: 0;
    /*CSS animation applied for sub menu : Slide from Top */
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center; }
  ul.main-nav > li:hover > div.sub-menu-block {
    background-color: #f9f9f9;
    visibility: visible;
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg); }
  ul.main-nav > li > div.sub-menu-block > * {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    opacity: 0; }
  ul.main-nav > li:hover > div.sub-menu-block > * {
    opacity: 1; }
  .sub-menu-head {
    font-size: 20px; }
  /* List Separator: Outer Border */
  /*
    header.dark ul.main-nav > li > a { border-right: 1px solid #bbb; }
    header.light ul.main-nav > li > a { border-right: 1px solid #666; }
    */
  /* List Separator: Inner Border */
  ul.main-nav > li > a:after {
    content: '';
    width: 1px;
    height: 62px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2; }
  /*
    header.dark ul.main-nav > li > a:after { background-color: #777; }
    header.light ul.main-nav > li > a:after { background-color: #999; }
    */
  /* Drop Down/Up Arrow for Mega Menu */
  ul.main-nav > li > a.mega-menu > span {
    display: block;
    vertical-align: middle; }
  ul.main-nav > li > a.mega-menu > span:after {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--menu-font-color, #ffffff);
    content: '';
    background-color: transparent;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle; }
  ul.main-nav > li:hover > a.mega-menu span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 0px solid transparent;
    border-bottom: 5px solid var(--menu-font-color-hover, #000000); }
  .banners-area {
    border-top: 1px solid #ccc; } }

/*
  .top-level-link{
    height: 100%;
  }
  */
.spriteParent {
  position: absolute;
  width: 100%;
  z-index: 100;
  -moz-transform: rotate(20deg);
  -webkit-transform: rotate(20deg);
  -o-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg); }

.image-gallery-slide-wrapper:hover {
  cursor: pointer; }
