.vc_images_carousel .vc_carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style: none;
    text-align: center;
}
.vc_images_carousel .vc_carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid grey;
    background-color: grey;
}
.vc_images_carousel .vc_carousel-indicators .vc_active {
    margin: 1px;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border: 1px solid grey;
}
.vc_images_carousel .vc_carousel-indicators .vc_partial {
    opacity: 0.5;
}
.vc_images_carousel {
    position: relative;
}
.vc_images_carousel .vc_carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
}
.vc_images_carousel .vc_carousel-inner .vc_carousel-slideline {
    position: relative;
    overflow: hidden;
}
.vc_images_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner {
    left: 0;
    position: relative;
    width: 100%;
}
.vc_images_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner.vc_transition {
    transition: 0.5s ease left;
}
.vc_images_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner.vc_transition.vc_swipe-transition {
    transition-duration: 0.2s;
}
.vc_images_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner > .vc_item {
    float: left;
    visibility: hidden;
}
.vc_images_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner > .vc_item > a > img,
.vc_images_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner > .vc_item > img {
    display: block;
    max-width: 100%;
    height: auto;
    line-height: 1;
}
.vc_images_carousel.vc_per-view-more .vc_carousel-slideline .vc_carousel-slideline-inner > .vc_item > .vc_inner {
    margin: 0 10px;
}
.vc_images_carousel.vc_carousel_vertical .vc_carousel-slideline {
    overflow: hidden;
}
.vc_images_carousel.vc_carousel_vertical .vc_carousel-slideline .vc_carousel-slideline-inner {
    width: 100%;
}
.vc_images_carousel.vc_carousel_vertical .vc_carousel-slideline .vc_carousel-slideline-inner.vc_transition {
    transition-property: top;
}
.vc_images_carousel.vc_carousel_vertical .vc_carousel-slideline .vc_carousel-slideline-inner .vc_item {
    float: none;
}
.vc_images_carousel.vc_carousel_vertical .vc_carousel-indicators {
    position: absolute;
    top: 2%;
    right: 3%;
    left: auto;
    width: 10px;
}
.vc_images_carousel.vc_carousel_vertical .vc_carousel-indicators li {
    display: block;
    margin: 0 auto 3px;
    width: 6px;
    height: 6px;
}
.vc_images_carousel.vc_carousel_vertical .vc_carousel-indicators li.vc_active {
    width: 8px;
    height: 8px;
    margin: 0 auto 1px;
}
.vc_images_carousel.vc_build .vc_carousel-slideline .vc_carousel-slideline-inner > .vc_item {
    visibility: visible;
}
.vc_images_carousel .vc_carousel-control {
    position: absolute;
    padding: 0;
    top: 50%;
    left: 0;
    bottom: 0;
    margin-top: -15px;
    width: 60px;
    height: 48px;
    opacity: 0.5;
    font-size: 30px;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    -webkit-tap-highlight-color: transparent;
}
.vc_images_carousel .vc_carousel-control:focus,
.vc_images_carousel .vc_carousel-control:hover {
    text-decoration: none;
    opacity: 0.9;
}
.vc_images_carousel .vc_carousel-control.vc_right {
    left: auto;
    right: 0;
}
.vc_images_carousel .vc_carousel-control .glyphicon-chevron-left,
.vc_images_carousel .vc_carousel-control .glyphicon-chevron-right,
.vc_images_carousel .vc_carousel-control .icon-next,
.vc_images_carousel .vc_carousel-control .icon-prev {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    display: inline-block;
}
.vc_images_carousel .vc_carousel-control .icon-next,
.vc_images_carousel .vc_carousel-control .icon-prev {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    font-family: serif;
}
.vc_images_carousel .vc_carousel-control .icon-prev:before {
    content: "\2039";
    position: relative;
    top: -10px;
}
.vc_images_carousel .vc_carousel-control .icon-next:before {
    content: "\203A";
    position: relative;
    top: -10px;
}
.vc_images_carousel .vc_carousel-caption {
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 20px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.vc_images_carousel .vc_carousel-caption .btn {
    text-shadow: none;
}
@media screen and (min-width: 768px) {
    .vc_images_carousel .vc_carousel-control .icon-next,
    .vc_images_carousel .vc_carousel-control .icon-prev {
        width: 30px;
        height: 30px;
        margin-top: -15px;
        margin-left: -15px;
        font-size: 30px;
        display: block;
        padding: 0;
    }
    .vc_images_carousel .vc_carousel-caption {
        left: 20%;
        right: 20%;
        padding-bottom: 30px;
    }
    .vc_images_carousel .vc_carousel-indicators {
        bottom: 20px;
    }
}
.vc_images_carousel .clearfix:after,
.vc_images_carousel .clearfix:before {
    content: " ";
    display: table;
}
.vc_images_carousel .clearfix:after {
    clear: both;
}
.vc_images_carousel .pull-right {
    float: right !important;
}
.vc_images_carousel .pull-left {
    float: left !important;
}
.vc_images_carousel .hide {
    display: none !important;
}
.vc_images_carousel .show {
    display: block !important;
}
.vc_images_carousel .invisible {
    visibility: hidden;
}
.vc_images_carousel .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}
.vc_images_carousel ol.vc_carousel-indicators {
    margin: 0 0 0 -30%;
}
.vc_images_carousel .vc_carousel_vertical ol.vc_carousel-indicators {
    margin: 1px 0 0 0;
    bottom: auto;
}
.vc_images_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner > .item > a {
    display: block;
}
.vc_images_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner > .item > a > img {
    margin: auto;
}
.vc_carousel .vc_carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style: none;
    text-align: center;
}
.vc_carousel .vc_carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid grey;
    background-color: grey;
}
.vc_carousel .vc_carousel-indicators .vc_active {
    margin: 1px;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border: 1px solid grey;
}
.vc_carousel .vc_carousel-indicators .vc_partial {
    opacity: 0.5;
}
.vc_carousel {
    position: relative;
}
.vc_carousel .vc_carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
}
.vc_carousel .vc_carousel-inner .vc_carousel-slideline {
    position: relative;
    overflow: hidden;
}
.vc_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner {
    left: 0;
    position: relative;
    width: 100%;
}
.vc_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner.vc_transition {
    transition: 0.5s ease left;
}
.vc_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner.vc_transition.vc_swipe-transition {
    transition-duration: 0.2s;
}
.vc_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner > .vc_item {
    float: left;
    visibility: hidden;
}
.vc_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner > .vc_item > a > img,
.vc_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner > .vc_item > img {
    display: block;
    max-width: 100%;
    height: auto;
    line-height: 1;
}
.vc_carousel.vc_per-view-more .vc_carousel-slideline .vc_carousel-slideline-inner > .vc_item > .vc_inner {
    margin: 0 10px;
}
.vc_carousel.vc_carousel_vertical .vc_carousel-slideline {
    overflow: hidden;
}
.vc_carousel.vc_carousel_vertical .vc_carousel-slideline .vc_carousel-slideline-inner {
    width: 100%;
}
.vc_carousel.vc_carousel_vertical .vc_carousel-slideline .vc_carousel-slideline-inner.vc_transition {
    transition-property: top;
}
.vc_carousel.vc_carousel_vertical .vc_carousel-slideline .vc_carousel-slideline-inner .vc_item {
    float: none;
}
.vc_carousel.vc_carousel_vertical .vc_carousel-indicators {
    position: absolute;
    top: 2%;
    right: 3%;
    left: auto;
    width: 10px;
}
.vc_carousel.vc_carousel_vertical .vc_carousel-indicators li {
    display: block;
    margin: 0 auto 3px;
    width: 6px;
    height: 6px;
}
.vc_carousel.vc_carousel_vertical .vc_carousel-indicators li.vc_active {
    width: 8px;
    height: 8px;
    margin: 0 auto 1px;
}
.vc_carousel.vc_build .vc_carousel-slideline .vc_carousel-slideline-inner > .vc_item {
    visibility: visible;
}
.vc_carousel .vc_carousel-control {
    position: absolute;
    padding: 0;
    top: 50%;
    left: 0;
    bottom: 0;
    margin-top: -15px;
    width: 60px;
    height: 30px;
    opacity: 0.5;
    font-size: 30px;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    -webkit-tap-highlight-color: transparent;
}
.vc_carousel .vc_carousel-control:focus,
.vc_carousel .vc_carousel-control:hover {
    text-decoration: none;
    opacity: 0.9;
}
.vc_carousel .vc_carousel-control.vc_right {
    left: auto;
    right: 0;
}
.vc_carousel .vc_carousel-control .glyphicon-chevron-left,
.vc_carousel .vc_carousel-control .glyphicon-chevron-right,
.vc_carousel .vc_carousel-control .icon-next,
.vc_carousel .vc_carousel-control .icon-prev {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    display: inline-block;
}
.vc_carousel .vc_carousel-control .icon-next,
.vc_carousel .vc_carousel-control .icon-prev {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    font-family: serif;
}
.vc_carousel .vc_carousel-control .icon-prev:before {
    content: "\2039";
    position: relative;
    top: -10px;
}
.vc_carousel .vc_carousel-control .icon-next:before {
    content: "\203A";
    position: relative;
    top: -10px;
}
.vc_carousel .vc_carousel-caption {
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 20px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.vc_carousel .vc_carousel-caption .btn {
    text-shadow: none;
}
@media screen and (min-width: 768px) {
    .vc_carousel .vc_carousel-control .icon-next,
    .vc_carousel .vc_carousel-control .icon-prev {
        width: 30px;
        height: 30px;
        margin-top: -15px;
        margin-left: -15px;
        font-size: 30px;
        display: block;
        padding: 0;
    }
    .vc_carousel .vc_carousel-caption {
        left: 20%;
        right: 20%;
        padding-bottom: 30px;
    }
    .vc_carousel .vc_carousel-indicators {
        bottom: 20px;
    }
}
.vc_carousel .clearfix:after,
.vc_carousel .clearfix:before {
    content: " ";
    display: table;
}
.vc_carousel .clearfix:after {
    clear: both;
}
.vc_carousel .pull-right {
    float: right !important;
}
.vc_carousel .pull-left {
    float: left !important;
}
.vc_carousel .hide {
    display: none !important;
}
.vc_carousel .show {
    display: block !important;
}
.vc_carousel .invisible {
    visibility: hidden;
}
.vc_carousel .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}
.vc_carousel ol.vc_carousel-indicators {
    margin: 0 0 0 -30%;
}
.vc_carousel .vc_carousel_vertical ol.vc_carousel-indicators {
    margin: 1px 0 0 0;
    bottom: auto;
}
.vc_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner > .item > a {
    display: block;
}
.vc_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner > .item > a > img {
    margin: auto;
}
