@charset "UTF-8";
body {
  color: var(--color-form, "#000"); }

.header {
  /*    position: fixed;*/
  z-index: 200;
  width: 100%;
  /*    margin-top: -70px;*/ }

@media (max-width: 1024px) {
  .header {
    /*    margin-top: -160px;  */ } }

.content .rs-panel-heading {
  font-weight: bold;
  border-bottom: 1px solid #e5e5ea; }

.sectionTitle {
  font-weight: var(--body-title-font-weight, "400");
  letter-spacing: 0px;
  text-transform: var(--body-title-font-text-transform, "uppercase");
  color: var(--body-title-font-color, "#000000");
  margin: 0;
  font-size: var(--body-title-font-size, "28px");
  line-height: 30px; }

@media (max-width: 1024px) {
  .sectionTitle {
    font-size: 24px !important;
    line-height: 26px; } }

@media (max-width: 768px) {
  .sectionTitle {
    font-size: 20px !important;
    letter-spacing: 3px !important;
    line-height: 22px; } }

@media (max-width: 600px) {
  .sectionTitle {
    font-size: 16px !important;
    letter-spacing: 2px !important;
    line-height: 17px; } }

@media (max-width: 480px) {
  .sectionTitle {
    font-size: 14px !important;
    letter-spacing: 0px !important;
    line-height: 15px; } }

.product {
  padding: 10px; }

.category {
  padding: 10px; }

.category .rs-panel-heading {
  font-weight: bold;
  border-bottom: 0px;
  /* solid #e5e5ea;*/
  text-transform: capitalize; }

.category .title {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 10px 10px 10px;
  /*    text-transform: capitalize;*/
  font-weight: bold;
  font-size: x-large;
  margin-bottom: 20px;
  height: 60px; }

@media (max-width: 768px) {
  .category .title {
    font-size: large;
    height: 55px; } }

@media (max-width: 500px) {
  .category .title {
    font-size: medium;
    height: 50px; } }

.subcategory {
  padding: 10px; }

.subcategory .rs-panel-heading {
  font-weight: bold;
  border-bottom: 0px;
  /* solid #e5e5ea;*/
  text-transform: capitalize; }

.subcategory .title {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 10px 10px 10px;
  /*    text-transform: capitalize;*/
  font-weight: 400;
  font-size: medium;
  margin-bottom: 20px;
  height: 60px; }

.subcategory .image {
  width: 100%;
  height: auto; }

@media (max-width: 768px) {
  .subcategory .title {
    font-size: medium;
    height: 55px; } }

@media (max-width: 500px) {
  .subcategory .title {
    font-size: medium;
    height: 50px; } }

.rs-btn-link:hover {
  text-decoration: none; }

.rs-btn-link:hover .sectionTitle {
  color: var(--body-title-font-color-hover, "#000000");
  font-weight: var(--body-title-font-weight, "400"); }

/*
.rs-btn-primary {
    font-family: 'Futura-Book';
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
    font-size: 1.4rem;
    border-radius:0px;
    padding: 15px;
}  
.rs-btn-primary:hover {
    background-color: #000!important;
    color: #fff;
    transition: all .3s;
}  
*/
.rs-alert-container {
  left: 85%; }

.header a:hover, a:focus {
  text-decoration: none; }

.filtroCategorias .rs-btn-group-vertical {
  display: inline; }

.rs-checkbox-inline {
  display: inherit; }

.rs-checkbox-checker {
  padding-top: 8px;
  padding-bottom: 0px;
  min-height: 0px;
  font-size: small; }

.rs-checkbox-wrapper {
  top: 4px; }

.rs-checkbox-wrapper::after {
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px; }

/* FILTROS SCROLL*/
.filtroTipoProductoScroll {
  overflow-y: scroll; }

.filtrosScroll {
  height: 300px;
  overflow-y: scroll; }

.filtrosScroll ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px; }

.filtrosScroll ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(110, 110, 110, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }

.filtrosPanelSM {
  padding-right: 10px;
  /*position: absolute;*/
  z-index: 100;
  background-color: white;
  border: 1px solid lightgray;
  padding: 0px 10px;
  width: 100%; }

.hideSideBar {
  display: none;
  flex: unset !important;
  width: 0px !important; }

.showSideBar {
  display: inline;
  flex: 0 0 210px !important;
  width: 210px !important;
  padding: 10px; }

/* BUSCADOR */
.rs-input-group .rs-input {
  position: unset; }

/*
.show-container>.rs-container {
    margin-bottom:40px
   }
   .show-container .rs-content,
   .show-container .rs-footer,
   .show-container .rs-header,
   .show-container .rs-sidebar {
    background:#3498ff;
    color:#fff;
    text-align:center
   }
   .show-container .rs-footer,
   .show-container .rs-header {
    line-height:56px;
    background:#59afff;
   }
   .show-container .rs-content {
    line-height:120px;
    background:#80c4ff
   }
   .show-container .rs-sidebar {
    line-height:120px
   }

   */
.rc-footer {
  background-color: var(--footer-background, #ffffff); }

.rc-footer-item-icon {
  width: 26px; }

.hideSM {
  display: inline; }

@media (max-width: 1024px) {
  .hideSM {
    display: none; } }

.image-gallery-content.fullscreen {
  background: #fff; }

@media (max-width: 500px) {
  .rs-drawer-content {
    top: 0;
    right: 0;
    width: 40%;
    min-width: 100%;
    bottom: 0; } }

@media (min-width: 501px) {
  .rs-drawer-content {
    top: 0;
    right: 0;
    width: 40%;
    min-width: 500px;
    bottom: 0; } }

.rs-divider-horizontal {
  height: 1px;
  margin: 12px 0; }

.vc_item {
  height: auto !important; }

.vc_inner > img {
  width: 100% !important;
  height: auto !important; }

.vc_inner > a > img {
  width: 100% !important;
  height: auto !important; }

.vc_row {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.vc_column-inner {
  padding-left: var(--page-padding, "20px") !important;
  padding-right: var(--page-padding, "20px") !important; }

@media (max-width: 468px) {
  .rs-btn {
    padding: 8px 4px; }
  .cart-item-details .rs-flex-box-grid-item {
    font-size: 10px; } }

@media (max-width: 468px) {
  .rs-btn-modal {
    width: 130px; } }

@media (min-width: 469px) {
  .rs-btn-modal {
    width: 130px; } }

/* Formulario dinamico */
.formulario {
  width: 100%;
  position: relative;
  height: 50px;
  overflow: hidden;
  margin: 10px; }

.formulario-redes {
  width: 100%;
  position: relative;
  height: 50px;
  overflow: hidden;
  align-self: flex-end; }

.formularioMarket {
  width: 50%;
  position: relative;
  height: 50px;
  overflow: hidden; }

.formularioRadio {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
  margin-top: 15px; }

.formulario .inpute {
  width: 100%;
  height: 100%;
  color: #595f6e;
  padding-top: 20px;
  border: none;
  outline: none; }

.formularioRadio .inputRadio {
  margin-right: 5px; }

.formulario select {
  width: 100%;
  height: 100%;
  color: #595f6e;
  padding-top: 20px;
  border: none;
  outline: none; }

.formulario .radios {
  position: absolute;
  bottom: 0px;
  left: 0%;
  width: 100%;
  height: 100%;
  color: red;
  pointer-events: none;
  border-bottom: none; }

.formulario label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 1px solid black; }

.formularioRadio .radioTitle {
  color: black;
  margin: 4px 0px 4px 8px; }

.formulario label::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -1px;
  height: 100%;
  width: 100%;
  border-bottom: 3px solid var(--color-form, "#000");
  transform: translateX(-100%);
  transition: transform 0.3s ease; }

.formulario-content-name {
  position: absolute;
  bottom: 5px;
  left: 10px;
  transition: all 0.3s ease; }

.formulario-textArea-span {
  margin-bottom: 5px; }

.formulario-textArea-span {
  color: var(--menu-font-color, #ffffff); }

.formulario select:valid + .formulario-label-name {
  transform: translateY(-100%);
  font-size: 14px;
  color: red; }

.formulario input:focus + .formulario-label-name .formulario-content-name,
.formulario input:valid + .formulario-label-name .formulario-content-name {
  transform: translateY(-100%);
  font-size: 14px;
  color: var(--menu-font-color, #ffffff); }

.formulario input:focus + .formulario-label-name::after,
.formulario input:valid + .formulario-label-name::after {
  transform: translateX(0%); }

.formulario input:invalid:focus + .formulario-label-name .formulario-content-name {
  transform: translateY(-100%);
  font-size: 14px;
  color: red; }

.formularioRadio textarea:invalid:focus + .formulario-textArea-label .formulario-textArea-span {
  transform: translateY(-100%);
  font-size: 14px;
  color: red; }

.rs-modal-xs {
  width: fit-content; }

/* DIV SCROLL*/
.scrollDiv {
  overflow-y: auto;
  border: 0px solid lightgray;
  line-height: 1em; }

.scrollDiv::-webkit-scrollbar {
  -webkit-appearance: none; }

.scrollDiv::-webkit-scrollbar:vertical {
  width: 11px; }

.scrollDiv::-webkit-scrollbar:horizontal {
  height: 11px; }

.scrollDiv::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5); }

.rs-panel-heading, .rs-panel-body {
  padding: 10px; }

.rs-drawer-top.rs-drawer-xs, .rs-drawer-bottom.rs-drawer-xs {
  height: 390px; }

.rs-drawer-body {
  position: relative;
  margin: 10px 0px 10px 10px;
  overflow: auto; }

.rs-radio-checker {
  padding-top: 10px;
  padding-bottom: 1px;
  padding-left: 36px;
  min-height: 25px;
  line-height: 1;
  position: relative; }

.titleMetodoEnvio {
  margin: 5px;
  text-align: center;
  font-size: initial;
  font-weight: bold; }

.imagenMetodoEnvio {
  margin: auto auto auto auto; }

.modal-body {
  padding: 20px; }

.modal-header {
  padding: 20px; }

.modal-footer {
  text-align: end;
  padding: 20px; }

.rs-badge-wrapper .rs-badge-content {
  position: absolute;
  top: 10px;
  right: 20px;
  transform: translate(50%, -50%);
  z-index: 1; }

.rs-modal-content {
  position: relative;
  background-color: #fff;
  border-radius: 6px;
  outline: 0;
  box-shadow: 0px;
  padding: 0px; }

.rs-modal-wrapper {
  position: fixed;
  overflow: auto;
  z-index: 1050;
  top: 25%;
  bottom: 0;
  right: 0;
  left: 0; }

.confirm-oxxo {
  padding: 0.5rem 1.2rem;
  margin: 2px;
  display: block; }

.grid-mi-tienda {
  display: grid;
  grid-template-columns: 1fr,1fr;
  padding: 20px; }

@media (max-width: 1200px) {
  .rs-modal-md {
    width: 80%;
    height: auto; } }

@media (min-width: 1199px) {
  .rs-modal-md {
    width: 800px;
    height: auto; } }

input[type="file"] {
  display: none; }

.custom-file-upload {
  border: 40% #5EBFBC;
  cursor: pointer; }

.custom-file-market {
  border: 1px solid #ccc;
  margin: 0 auto .625em;
  position: relative;
  display: inline-table;
  background-color: #fff;
  cursor: pointer;
  float: left; }

.custom-file-upload-buttom {
  border: 1px solid #ccc;
  display: inline-table;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 14px;
  color: white;
  background-color: var(--color-form, "#000"); }

.custom-file-upload-galeria {
  border: 1px solid #ccc;
  display: inline-table;
  margin: 15px; }

.custom-file-upload-round {
  background-color: var(--color-form, "#000");
  border: none;
  color: white;
  padding: 2%;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 13%;
  top: 80%; }

.custom-header-upload {
  border: 1px solid #e6f3f3;
  display: inline-table;
  cursor: pointer;
  width: 100%;
  max-height: 550px;
  -webkit-background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative; }

.txt-img-header {
  position: absolute;
  top: 109%;
  left: 25%;
  right: 16%; }

@media (max-width: 600px) {
  .custom-header-upload {
    border: 1px solid #ccc;
    display: inline-table;
    cursor: pointer;
    width: 100%;
    /*        height: 9em;*/
    -webkit-background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
  .txt-img-header {
    position: absolute;
    top: 103%;
    left: 34%;
    right: 13%; } }

@media (min-width: 600px) {
  .margin-logo {
    margin: -61px 0px 48px 31px;
    position: relative; } }

.margin-logo {
  margin: -34px 0px 48px 31px;
  position: relative; }

.custom-header-upload-round {
  background-color: var(--color-form, "#000");
  border: none;
  color: white;
  padding: 2%;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 91%;
  top: 89%; }

.margin-logo-public {
  margin: -34px 0px 48px 31px;
  position: relative; }

.margin-logo-public .custom-file-upload > img {
  border: 1px solid #e6f3f3;
  width: 80%;
  height: auto;
  padding: 0px;
  background-color: white;
  position: relative; }

.margin-logo .custom-file-upload > img {
  border: 1px solid #e6f3f3;
  width: 18%;
  height: auto;
  padding: 0px;
  background-color: white;
  position: relative; }

@media (max-width: 600px) {
  .custom-header-upload {
    border: 1px solid #ccc;
    display: inline-table;
    cursor: pointer;
    width: 100%;
    /*        height: 9em;*/
    -webkit-background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
  .margin-logo {
    margin: -61px 0px 0px 31px;
    position: relative; }
  .margin-logo-public {
    margin: -30px 0px 0px 31px;
    position: relative; } }

.rs-nav-item > .rs-nav-item-content {
  padding: 20px 12px; }

.productoPestanas {
  margin-top: 20px; }

.miTiendaTabs > a {
  height: 60px; }

/*
.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle, .rs-nav .rs-nav-item-active > .rs-nav-item-content, .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover, .rs-nav .rs-nav-item-active > .rs-nav-item-content:hover, .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus, .rs-nav .rs-nav-item-active > .rs-nav-item-content:focus, .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active, .rs-nav .rs-nav-item-active > .rs-nav-item-content:active {
    color: $colorSecundario!important;
}
*/
/* Formulario dinamico tienda */
.formulario_tienda {
  width: 100%;
  position: relative;
  margin: 15px 0px; }

.formulario_dinamico {
  max-width: 100%;
  position: relative;
  margin: 15px; }

.formulario_dinamico .boton_formulario_dinamico {
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 15px;
  /*
    margin-top:15px;
    background-color: $color;
    font-size: 16px;
*/ }

.formulario_tienda_horario {
  width: 100%;
  position: relative;
  margin: 5px; }

.formulario_tienda .input_tienda {
  width: 100%;
  height: 46px;
  border-color: #E6F3F3; }

.fixed-menu-mi-tienda {
  border-color: #5ebfbc;
  position: fixed;
  z-index: 1000;
  background-color: white;
  padding-top: 0px;
  text-align: center;
  max-width: var(--page-max-width, 1140px);
  width: 100%; }

.rs-nav-item > .rs-nav-item-content {
  padding: 18px 3px; }

.notificacionTienda-green {
  background-color: #5cb85c;
  max-width: 250px;
  padding: 10px;
  color: #fff; }

.notificacionTienda-yellow {
  background-color: #f9efc0;
  max-width: 100%;
  padding: 10px; }

.notificacionTienda-blue {
  background-color: #c3dfd7;
  max-width: 100%;
  padding: 10px; }

.notificacionTienda-red {
  background-color: #ee9a9a;
  max-width: 250px;
  padding: 10px; }

.notificacionTienda {
  font-size: 16px; }

.div-nombre-nav-mi-tienda {
  white-space: normal;
  text-align: left; }

.miTiendaPanel {
  display: "table";
  margin-left: "auto";
  margin-right: "auto";
  width: "100%";
  height: 'auto';
  padding: 0px; }

.div-nombre-nav-mi-tienda {
  font-weight: normal; }

.rs-nav-item-active .div-nombre-nav-mi-tienda {
  font-weight: bold !important; }

@media (max-width: 991px) {
  .div-numero-mi-tienda {
    text-align: center; }
  .div-nombre-nav-mi-tienda {
    margin-left: 0px; }
  .p-numero-nav-mi-tienda {
    background-color: var(--color-secundario, #ffffff);
    border: none;
    color: white;
    padding: 4px 11px;
    display: inline-block;
    border-radius: 50%;
    margin: -15px;
    font-size: 19px; } }

@media (min-width: 992px) {
  .div-numero-mi-tienda {
    text-align: right; }
  .div-nombre-nav-mi-tienda {
    margin-left: 10px; }
  .p-numero-nav-mi-tienda {
    background-color: var(--color-secundario, #ffffff);
    border: none;
    color: white;
    padding: 4px 11px;
    display: inline-block;
    border-radius: 50%;
    margin: -15px;
    font-size: 19px; } }

@media (max-width: 770px) {
  .margin-logo .custom-file-upload > img {
    width: 30%; }
  .div-numero-mi-tienda {
    text-align: center; }
  .div-nombre-nav-mi-tienda {
    margin-left: 0px;
    font-size: 13px; }
  .p-numero-nav-mi-tienda {
    background-color: var(--color-secundario, #ffffff);
    border: none;
    color: white;
    padding: 4px 11px;
    display: inline-block;
    border-radius: 50%;
    margin: -15px;
    font-size: 17px; }
  .miTiendaTabs > a {
    height: 60px;
    padding-top: 15px !important; }
  .notificacionTienda {
    font-size: 14px; } }

@media (max-width: 480px) {
  .margin-logo .custom-file-upload > img {
    width: 40%; }
  .margin-logo-public .custom-file-upload > img {
    width: 40%; }
  .div-numero-mi-tienda {
    text-align: center; }
  .div-nombre-nav-mi-tienda {
    margin-left: 0px;
    font-size: 11px;
    padding-top: 2px;
    text-align: center; }
  .p-numero-nav-mi-tienda {
    background-color: var(--color-secundario, #ffffff);
    border: none;
    color: white;
    padding: 3px ​7px;
    display: inline-block;
    border-radius: 50%;
    margin: -15px;
    font-size: 10px; }
  .miTiendaTabs > a {
    height: 60px;
    padding-top: 4px !important; }
  .notificacionTienda {
    font-size: 14px; } }

.flexible-nav {
  white-space: nowrap;
  position: fixed; }

.div-boton-next {
  margin: 10px; }

.formulario_tienda .input_tienda_txtArea {
  width: 100%;
  border-color: #E6F3F3; }

.boton_tienda_footer {
  /* margin-top: 15px; */
  background-color: var(--color-form, "#000");
  color: #fff;
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* display: block; */
  padding: 15px 20px;
  /* 3% 15%; NO USAR PORCENTAJES EN LOS PADDINGS*/
  font-size: 16px; }

.div_tienda_footer_next {
  /* width: 100%; */
  /* position: relative; */
  text-align: right;
  margin-right: 10px;
  /* display: block; */
  /* margin-right: auto; */
  /* margin-left: auto; */ }

.div_tienda_footer_behind {
  /* width: 100%; */
  /* position: relative; */
  text-align: left;
  margin-left: 10px;
  /* display: block; */
  /* margin-right: auto; */
  /* margin-left: auto; */ }

.formulario_tienda .input_tienda_redes {
  width: 100%;
  height: 46px;
  border-color: #E6F3F3;
  margin: 0px 0px; }

.formulario_tienda .label_input_tienda {
  height: 100%;
  color: #4c4a4e;
  display: flex;
  margin-bottom: 6px; }

.formulario_tienda .label_input_tienda_redes {
  height: 100%;
  color: #4c4a4e;
  display: flex;
  margin-bottom: 6px;
  margin: 0px 0px; }

.formulario_tienda input:focus {
  border-color: var(--color-form, "#000"); }

.formulario_tienda textarea:focus {
  border-color: var(--color-form, "#000"); }

.formulario_tienda select:focus {
  border-color: var(--color-form, "#000"); }

.formulario_tienda input:invalid {
  border-color: var(--color-form, "#000"); }

.psw-eye {
  position: relative;
  display: block;
  bottom: 22px;
  cursor: pointer;
  float: right;
  right: 10px; }

.fechaPosibleEntrega {
  display: inline-block;
  padding: 2px 8px;
  background-color: var(--color-secundario, #ffffff);
  color: white;
  border-radius: 0px;
  line-height: 1.66666667; }

.formulario_tienda input:invalid + b:before {
  content: "\FFFDRequerido!";
  right: 23px;
  position: absolute;
  bottom: 3px;
  height: auto;
  width: 28%;
  color: var(--color-form, "#000"); }

.formulario_tienda .img_input {
  right: 23px;
  position: absolute;
  bottom: 3px;
  height: auto;
  width: 28%; }

.boton_tienda {
  margin-top: 15px;
  background-color: var(--color-form, "#000");
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 15px 20px;
  font-size: 16px; }

.formulario_tienda_horario .boton_tienda_horario {
  background-color: var(--color-form, "#000");
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 5% 9%;
  font-size: 12px;
  color: white; }

.formulario_tienda .span_input_tienda_redes {
  margin-top: auto;
  display: block;
  margin-left: 5px;
  font-size: smaller; }

.formulario_tienda_tipo {
  width: 100%;
  position: relative;
  margin: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: auto;
  display: block;
  text-align: end; }

.formulario_tienda_tipo .boton_tienda_tipo {
  background-color: var(--color-form, "#000");
  margin-left: auto;
  color: white;
  padding: 10px; }

.formulario_tienda_tipo .boton_tienda_asignar_horario {
  background-color: var(--color-form, "#000");
  margin-left: 5px;
  padding: 10px;
  color: white;
  font-weight: bold; }

.boton_tienda_horario_check_0 {
  background-color: var(--color-form, "#000") !important;
  color: #fff;
  width: 100%;
  font-size: 12px;
  margin-top: 5px; }

.boton_tienda_horario_check_1 {
  background-color: #5EBFBC !important;
  color: #fff;
  width: 100%;
  font-size: 12px;
  margin-top: 5px; }

.formulario_tienda_tipo .boton_tienda_cancelar {
  background-color: var(--color-secundario, #ffffff);
  margin-left: 5px;
  cursor: pointer;
  padding: 12px;
  color: white;
  font-weight: bold; }

.boton_cancelar {
  background-color: var(--color-secundario, #ffffff);
  margin-left: 5px;
  cursor: pointer;
  padding: 15px;
  color: white;
  font-weight: bold; }

.formulario_tienda_tipo .boton_tienda_cancelar_modal {
  background-color: var(--color-secundario, #ffffff);
  margin-left: 5px;
  cursor: pointer;
  padding: 15px;
  color: white;
  font-weight: bold; }

.boton_tienda_cancelar_modal {
  background-color: var(--color-secundario, #ffffff);
  margin-left: 5px;
  cursor: pointer;
  padding: 16px;
  color: white;
  font-weight: bold; }

.formulario_tienda_parrafo .tienda_parrafo_descripcion {
  margin-top: 20px;
  text-align: 'justify';
  text-justify: 'inter-word'; }

.formulario_tienda_parrafo {
  padding: "20px"; }

/*  ficha de productos */
.titleProductCard {
  font-size: 1.3rem; }

@media (max-width: 768px) {
  .titleProductCard {
    font-size: 1.2rem; } }

@media (max-width: 480px) {
  .titleProductCard {
    font-size: 1.4rem; } }

label {
  color: var(--menu-font-color, #ffffff); }

.rs-badge {
  display: inherit !important; }

.embed-container {
  position: relative;
  padding-bottom: 3100px;
  height: 0;
  overflow: hidden; }

.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0px solid lightgray; }

/* MI CUENTA */
.miCuentaBtn {
  margin-top: 10px;
  height: 20px;
  font-weight: bold;
  color: var(--menu-font-color, #ffffff); }

.panelMiCuenta {
  text-align: center; }

.panelMiCuenta .rs-panel-body {
  padding: 0px; }

.div-img-logo-detalle-producto {
  margin: 10px; }

.img-logo-detalle-producto {
  width: 100px;
  height: auto;
  border: 1px solid #e6f3f3;
  float: left;
  cursor: pointer; }

.titulo-logo-detalle-producto {
  margin: 28px 0px 0px 120px;
  letter-spacing: 0.05em;
  text-decoration: uppercase;
  cursor: pointer; }

@media (max-width: 480px) {
  .img-logo-detalle-producto {
    width: 70px; }
  .titulo-logo-detalle-producto {
    margin: 28px 0px 0px 90px; } }

.radio-group-producto-atributo {
  color: #575757;
  border: 0px;
  border-radius: 0px;
  margin-left: 0; }

.radio-producto-atributo {
  color: #575757;
  border: 1px solid #e5e5ea;
  /* border-radius: 6px; */
  background-color: white;
  margin: 5px; }

.p-radio-producto-atributo-checked {
  font-weight: bold;
  color: black; }

.p-radio-producto-atributo {
  font-weight: none; }

.div-titulo-producto-atributo {
  margin: 5px 10px;
  align-self: center; }

.div-input-producto-atributo {
  margin: 5px 10px; }

.div-principal-producto-atributo-LISTA {
  display: grid;
  grid-template-columns: auto; }

.div-principal-producto-atributo-TABLA {
  display: grid;
  grid-template-columns: auto auto; }

.select-producto-atributo {
  padding: 5px; }

.input-producto-atributo {
  padding: 5px; }

.div-mensaje-no-existencia {
  position: relative;
  text-align: center;
  /* margin: 0px 0px 0px 0px; */
  margin-bottom: 100px;
  font-size: xx-large; }

.div-radio-producto-atributo {
  border: 1px solid #e5e5ea;
  border-radius: 50px;
  padding: 3px; }

.div-radio-select-producto-atributo {
  border: 2px solid black;
  border-radius: 50px;
  padding: 3px; }

.button-back-order-detail {
  margin: 0px 0px 10px 20px;
  border-color: white; }

.div-direccion-con-geolocalizacion {
  margin-bottom: 20px; }

.p-direccion-con-geolocalizacion {
  text-align: center;
  margin-bottom: 20px; }

.rs-modal-header {
  padding: 20px; }

/*
.rs-btn-toggle{
    background-color:#999999;
}
.rs-btn-toggle:hover{
    background-color:#888888;
}
*/
.tiendaCardFormat {
  text-align: initial;
  color: black;
  padding: 1px 0px 0px 10px;
  margin: 0px;
  height: 43px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.rs-table-cell-header .rs-table-cell-content {
  padding: 5px 5px; }

.whatsPosition > div > div {
  z-index: 100;
  position: fixed; }

.rs-rate-sm {
  font-size: 16px; }

.rs-rate-character {
  margin: 3px 5px 3px 0; }

.rs-modal-sm {
  max-width: 600px;
  width: 90%; }

/* P R O D U C T */
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  border: 4px solid var(--gallery-active-thumbnail, "#000"); }

.FormRow {
  border-bottom: 1px solid var(--body-title-font-color, "#000000"); }
