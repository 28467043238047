.widget-area .widget + .widget {
	margin-top: 68px;
}
.widget-area  .widget-title {
	margin-bottom: 28px;
}
.wpb_widgetised_column .widget + .widget {
	margin-top: 50px;
}
.sidebar .widget:first-child .widget-title {
	margin-top: 0px;
}
.wpb_widgetised_column .widget:first-child .widget-title {
	margin-top: 0px;
}

.img-responsive.img-circle.wp-post-image {
	margin: 0 auto;
}
.widget_contacts .widget-title {
	margin-bottom: 21px;
}
.widget .gem-dummy {
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	width: 72px;
	height: 72px;
	float: left;
}
.widget  .gem-teams-image .gem-dummy {
	background-image:url("https://cms.concrad.com/wp-content/themes/thegem/images/default-avatar.png") ;
	background-size: 100%;
}
.widget  .gem-teams-image .gem-dummy:after {
	display: none;
}
.widget .gem-teams-image .gem-dummy {
	height: 128px;
	width: 128px;
	margin-left: 66px;
}
#colophon .widget .gem-dummy {
	background-color: #394050;
}
#colophon .widget .gem-dummy:after{
	color: #212331;
}
.widget .gem-dummy:after{
	content: '\E642';
	font-family: thegem-icons;
	font-size: 24px;
	position: absolute;
	margin-top: 24px;
	margin-left: 25px;
	font-weight: normal;

}



.Custom_Popular_Posts  .widget-title,
.Custom_Recent_Posts .widget-title,
.widget_meta .widget-title,
.thegem_news .widget-title,
.widget_recent_entries .widget-title{
	margin-bottom: 17px;
}

/*Arhives*/
.widget_archive li{
	list-style: none;
	padding-top: 12px;
	padding-left: 16px;
	padding-bottom: 12px;
}
.widget_archive li:nth-child(odd){
	background-color: #f7f9f8;
}

.widget_archive ul{
	padding-left: 0px;
}

.widget_archive a {
	text-transform: capitalize;
}

/*Arhives*/



/*Categories*/
.widget_recent_comments ul,
.widget_pages ul,
.widget_meta ul,
.widget_rss ul,
.widget_archive ul,
.diagram-item {
	margin-bottom: 0px;
}

/*Categories*/

/*Contacts*/
.gem-contacts-icon {
	float: left;
	font-family: thegem-icons;
	font-size: 20px;
}
.gem-contacts-item:before {
	vertical-align: top;
}
.gem-contacts-text {
	padding-left: 32px;
}
.sidebar .widget .gem-contacts-item,
.wpb_widgetised_column .gem-contacts-item {
	padding: 5px 0;
}
.sidebar .widget  .gem-contacts-item.gem-contacts-address {
	padding-bottom: 33px;
}
.sidebar .widget  .gem-contacts-item.gem-contacts-phone {
	padding-top: 28px;
}
.gem-contacts-item.gem-contacts-phone a,
#colophon .widget .gem-contacts-item.gem-contacts-phone a {
	color: inherit;
}
.site-footer .gem-contacts-email:before {
	font-family: thegem-icons;
	content: '\E607';
	float: left;
	font-size: 20px;
	float: left;
	margin-top: 2px;
	padding-right: 15px;
	font-weight: normal;
}
.site-footer .gem-contacts-phone:before {
	font-family: thegem-icons;
	content: '\E625';
	font-size: 20px;
	font-weight: normal;
}
.site-footer .gem-contacts-address:before {
	font-family: thegem-icons;
	content: '\E604';
	font-size: 20px;
	font-weight: normal;


}
.site-footer .gem-contacts-fax:before {
	font-family: thegem-icons;
	content: '\E606';
	float: left;
	font-size: 12px;
	padding-right: 15px;
	font-weight: normal;

}
.site-footer .gem-contacts-item {
	padding: 5px 0;
}
body .widget .gem-contacts-address:before,
body .wpb_widgetised_column .gem-contacts-address:before{
	font-family: thegem-icons;
	content: '\E604';
	font-size: 16px;
	display: inline-block;
	padding-right: 8px;
	font-weight: normal;
}
body .widget .gem-contacts-phone:before,
body .wpb_widgetised_column .gem-contacts-phone:before{
	font-family: thegem-icons;
	content: '\E625';
	font-size: 16px;
	display: inline-block;
	padding-right: 12px;
	font-weight: normal;
}
body .widget .gem-contacts-fax:before,
body .wpb_widgetised_column .gem-contacts-fax:before{
	font-family: thegem-icons;
	content: '\E606';
	float: left;
	display: inline-block;
	padding-right: 12px;
	font-size: 16px;
	font-weight: normal;
}
body .widget .gem-contacts-email:before,
body .wpb_widgetised_column .gem-contacts-email:before{
	font-family: thegem-icons;
	content: '\E607';
	float: left;
	display: inline-block;
	padding-right: 12px;
	font-size: 16px;
	margin-top: -1px;
	font-weight: normal;
}
body .widget .gem-contacts-website:before,
body .wpb_widgetised_column .gem-contacts-website:before {
	font-family: thegem-icons;
	content: '\E608';
	float: left;
	display: inline-block;
	padding-right: 12px;
	font-size: 16px;
}

/*Contacts*/



/*Custom pp posts*/
.gem-pp-posts-image img {
	border-radius: 50%;
	-webkit-border-radius:50%;
-moz-border-radius:50%;
	width: 72px;
	height: 72px;
}

.gem-pp-posts-text {
	display: table-cell;
	vertical-align: middle;

}
.gem-pp-posts-date {
	padding-left: 12px;
	display: block;
	margin-top: -2px;
}
.gem-pp-posts-item {
	display: block;
	margin-left: 12px;
}
.gem-pp-posts-image {
	display: table-cell;
}
.gem-pp-posts-news {
	padding-top: 14px;
	padding-bottom: 14px;
}
.gem-pp-posts-item > a {
	display: block;
}
.gem-pp-posts {
	padding-bottom: 14px;
	padding-top: 14px;
}
.gem-post-thumb img{
	width: 71px;
	height: 71px;
	position: absolute;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius:  50%;
	left: 0px;
	top: 0;
}
.isotope-inner {
	padding-left: 90px;
}
.gem-post-thumb-gem-dummy {
	height: 71px;
	left: 0px;
	position: absolute;
	top: 0;
	width: 71px;
}

.footer-widget-area .gem-pp-posts-image a:after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 72px;
	height: 72px;
	border-radius: 50%;
	background: rgba(255,255,255,0.2);
	opacity: 0;
}
.footer-widget-area .gem-pp-posts-image a:hover:after {
	opacity: 1;
}
.footer-widget-area .gem-pp-posts-image {
	position: relative;
}
.post-related-posts .gem-dummy:after {
	content: '\E642';
	font-family: thegem-icons;
	font-size: 24px;
	position: absolute;
	margin-top: 24px;
	margin-left: 25px;
	font-weight: normal;
}

.post-related-posts .gem-dummy:after {
	content: '\E642';
	font-family: thegem-icons;
	font-size: 24px;
	position: absolute;
	margin-top: 24px;
	margin-left: 25px;
	font-weight: normal;
}

/*Custom pp posts*/
.gem-post-thumb-conteiner {
	display: table-cell;
	vertical-align: middle;
}
.site-footer .gem-pp-posts {
	padding-bottom: 12px;
}

/*Custom rp posts*/
.footer-widget-area .posts {
	margin-top: -19px;
}
.Custom_Recent_Posts ul li:last-child,
.Custom_Popular_Posts ul li:last-child{
border-bottom: none;
}


.widget_recent_entries li {
	padding-bottom: 25px;
	padding-top: 20px;
	list-style: none;
}
.widget_recent_entries li:last-child {
	border-bottom: none;
}
.widget_recent_entries ul {
	margin: 0;
	padding: 0;
}
.widget_recent_entries .post-date {
	clear: both;
	display: block;
}

.site-footer .Custom_Recent_Posts .gem-pp-posts:last-child{

	border-bottom: none;
}

/*Custom rp posts*/

/*Calendar*/
.widget_calendar td a {

	display: block;
	font-weight: normal;
}
.widget_calendar table {
	text-align: center;
	width: 100%;
}
.widget_calendar #prev > a {
	float: left;
	padding-left: 12px;
}
.widget_calendar #next > a {
	float: right;
	padding-right: 12px;
}
.widget_calendar a {
	font-weight: bold;
}
.calendar_wrap {

	padding-bottom: 20px;
}
.widget_calendar caption {
	padding-bottom: 1px;
	padding-top: 1px;
}

.widget_calendar table {
	margin-left: 9%;
	text-align: center;
	width: 83%;
}
.widget_calendar td {
	padding: 2px;
	padding-bottom: 4px;
	padding-top: 4px;
}


.widget_calendar tfoot td.pad {
	display: none;
}
#wp-calendar caption:after {
	position: absolute;
	content: '';
	height: 39px;
	width: 39px;
	top: 0;
	background-color: #b9c7ca;
	right: 0;
}
#wp-calendar #prev a {
	position: absolute;
	left: 0;
	height: 39px;
	width: 39px;
	top: 0;
	padding-top: 7px;
	padding-bottom: 7px;
}
#wp-calendar #prev a:after {
	content: '\E637';
	font-family: thegem-icons;
	width: 38px;
	height: 38px;
	position: absolute;
	left: 0px;
	top: 0;
	line-height: 1.6;
	font-size: 24px;
}
#wp-calendar #next a:after {
	content:'\E638';
	font-family: thegem-icons;
	top: 0px;
	position: absolute;
	right: 0px;
	width: 38px;
	line-height: 1.6;
	height: 38px;
	font-size: 24px;
}
#wp-calendar #next a {
	position: absolute;
	right: 0;
	top: 0;
	height: 39px;
	width: 39px;
	padding-top: 7px;
	padding-bottom: 7px;
}


#wp-calendar caption {
	margin-bottom: 34px;
	letter-spacing: 0.08em;
}
.calendar_wrap {
	position: relative;
}
/*calendar*/

/*Diagram*/
.widget_diagram .skill-line {
	margin-right: 0px;
}
.widget_diagram .skill-amount {
	margin-top: -29px;
	font-weight: bold;
}
.widget_diagram .skill-element + .skill-element {
	margin: 18px 0 0;
}
/*Diagram*/

/*Facebook*/
.widget.Facebook div {
	padding: 20px;
}
/*Facebook*/

/*Flikr*/
.flickr-item {
	float: left;
	padding: 0 10px 10px 0;
}
.flickr-item img {
	width: 80px;
	height: 80px;
}
.widget.Custom_Flickr .flickr {
	list-style: none outside none;
	margin: 0 -10px 0 0;
	padding: 0;
}
.widget .flickr-item a,
.wpb_widgetised_column .widget .flickr-item a{
	position: relative;
	display: inline-block;
	vertical-align: top;
	overflow: hidden;
}
.widget .flickr-item a:before,
.wpb_widgetised_column .widget .flickr-item a:before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: 5;
}
.widget .flickr-item a:hover:before,
.wpb_widgetised_column .widget .flickr-item a:hover:before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 1;
	z-index: 5;
}
.widget .flickr-item a img,
.wpb_widgetised_column .widget .flickr-item a img{
	transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
}
.widget .flickr-item a:hover img,
.wpb_widgetised_column .widget .flickr-item a:hover img{
}
/*Flikr*/



/*MC form*/
.mc-form {
	position: relative;
	padding-top: 5px;
}
.wpb_widgetised_column .mc4wp-form-fields {
	padding: 29px;
	margin-top: 29px;
}
.sidebar .mc4wp-form-fields {
	padding: 29px;
	margin-top: 29px;
}
.widget_mc4wp_widget form {
	padding: 25px 23px 10px 26px;
}
.widget_mc4wp_widget p {
	line-height: 1.6;
}
#mc4wp_submit {
	width: 40px;
	font-weight: normal;
	height: 41px;
	position: absolute;
	right: 0;
	padding: 0;
	top: -8px;
	margin: 13px 0 0 0;
	font-size: 24px;
	font-family: thegem-icons;
	border-radius: 0px;
}


.mc4wp-alert {
	margin-top: 40px;
}
.gem-button-footer {
	border: medium none;
	margin-top: 21px;
}

.mc4wp-form input[type='email'] {
	padding: 7px 46px 7px 9px;
	width: 100%;
}



/*MC form*/

/*meta*/
.widget_meta li,
.widget_rss li {
	list-style: none;
	padding-top: 12px;
	padding-bottom: 12px;
	text-transform: capitalize;
}
.widget_meta li:last-child,
.widget_rss li:last-child,
.site-footer .widget_meta li:last-child,
.site-footer .widget_rss li:last-child{
	border-bottom: none;
}
.widget_meta ul,
.widget_rss ul{
	padding-left: 0px;
}
.widget_meta li:before,
.widget_rss li:before {
	content: '\E601';
	font-family: 'thegem-icons';
	font-size: 16px;
	font-weight: normal;
	padding-right: 8px;
	vertical-align: middle;
	position: relative;
	top: -2px;
}
.widget_meta abbr,
.widget_rss abbr {
	border-bottom: none;
}
/*meta*/

/*News*/
.gem-latest-news-image img {
	height: 72px;
	width: 72px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	webkit-border-radius: 50%;
}
.gem-latest-news-image {
	display: table-cell;
}
.gem-latest-news-title {
	display: table-cell;
	padding-left: 15px;
	vertical-align: middle;
}
.gem-latest-news {
	display: inline-block;
	padding-bottom: 17px;
	padding-top: 11px;
	width: 100%;
}
.gem-latest-news-title > span {
	display: block;
	margin-top: -1px;
}
.gem-latest-news-title > a {
	display: block;
}
.gem-latest-news-image .gem-dummy {
	height: 72px;
	width: 72px;
}
.widget .thegem_news,
.wpb_widgetised_column .widget .thegem_news{
	line-height: 1.5;
}

.gem-latest-news:first-child {
	padding-bottom: 16px;
	padding-top: 16px;
}
.gem-latest-news-image .gem-dummy {
	display: block;
}
/*News*/

/*Picturebox*/
.gem-picturebox-text {
	padding-top: 11px;
}
.gem-picturebox p{
	padding: 12px 26px 30px;
}
.gem-picturebox-image {
	text-align: center;
}
/*Picturebox*/

/*Proect-info*/
.project_info-item-style-2 .project_info-item:last-child  .value {
	border-bottom: medium none;
}
.project_info-item-style-1 .project_info-item .icon{

	display: inline-block;
	font-size: 24px;
	border-radius: 0px;
	width: 50px;
	height: 50px;
	text-align: center;
	color: #FFFFFF !important;
	background-color: transparent;
	vertical-align: middle;
	line-height: 2.1;

}

.project_info-item-style-1 .project_info-item .title {
	text-transform: uppercase;
}
.project_info-item-style-1  .project_info-item-title {
	vertical-align: top;
	padding-left: 16px;
}
.project_info-item-style-1  .project_info-item.iconed {
	padding: 28px;
	padding-left: 0;
}
.project_info-item-style-1  .project_info-item.iconed:last-child {
	border-bottom: none;
}
.widget.project_info  .widget-title,
.widget.project_info  .widget-title {
	margin-bottom: 32px;
}

.project_info-item-style-1 .project_info-item .value {
	padding-top: 0;
	padding-right: 18px;
	margin-top: -22px;
	padding-left: 71px;
}


.project_info-item-style-2 {
	padding-bottom: 20px;
}
.project_info-item-style-2 .icon {
	font-size: 24px;
	margin-right:16px;
	float: left;
	margin-top: 2px;
}

.project_info-item-style-2 .project_info-item {
	padding: 28px;
	margin-bottom: 17px;
}

.project_info-item-style-2  .project_info-item.iconed:last-child {
	margin-bottom: -24px;
}
.project_info-item-style-2 .icon{
	background-color: transparent !important;
}
.project_info-item-style-2 .value {
	padding-left: 42px;
	padding-top: 18px;
}
/*Proect-info*/

/*Recent commets*/
.widget_recent_comments ul {
	padding-left: 0px;
	margin-top: 30px;
}
.widget_recent_comments li {
	list-style: none;
	border-radius: 60px;
	border-bottom-left-radius: 0;
	padding-top:  14px;
	margin-bottom: 24px;
	padding-bottom: 20px;
	padding-left: 20px;
	padding-right: 20px;



}
.widget_recent_comments li:first-child {
	border-top: none;
}
.widget_recent_comments li:before {
	clear: both;
	content: '\E639';
	float: left;
	font-family: 'thegem-icons';
	font-size: 24px;
	font-weight: normal;
	height: 100%;
	padding-right: 14px;
	position: relative;
	top: 0;
	padding-bottom: 7px;
	width: 100%;
	text-align: center;
	vertical-align: middle;
}

.widget_recent_entries .post-date {
	margin-left: 48px;
}
.site-footer .widget_recent_entries li:first-child {
	background: none;
}
/*Recent commets*/

/*search*/

.widget .searchform input,
.wpb_widgetised_column .widget .searchform input{
	padding: 6px 10px;
}

.widget .searchform,
.wpb_widgetised_column .widget .searchform{
	text-align: center;
	position: relative;
}
.searchform button
{
	border: none;
}

.widget.widget_search .gem-button {
	font-size: 0;
	position: absolute;
	top: 0;
	right: 0;
	width: 43px;
	height: 38px;
	margin: 0;
	background-color: transparent !important;
}

.widget.widget_search .gem-button:before {
	content: '\E612';
	position: absolute;
	font-size: 24px;
	font-family: thegem-icons;
	left: 26%;
	font-weight: 100;
	margin-top: -3px;
	margin: 0;

}
.widget.widget_search input {
	width: 100%;
	padding-right: 50px;

}
/*search*/

/*tags*/
.tagcloud a:hover {
	text-decoration: none;
}
body .widget .tagcloud a {
	margin-right: 4px;
	text-decoration: none;
	margin-bottom: 6px;
	padding: 2px 9px;
	display: inline-block;
	font-size: 16px !important;
}
/*tags*/

/*teams*/
 .gem-client-item {
	float: left;
	padding-right: 30px;
	padding-left: 30px;
}
.gem_client-carousel {
	position: relative;
}
.gem-client-prev {
	margin-left: 0%;
}
.gem-client-next {
	margin-right: 0%;
}
.clients_title {
	text-align: center;
}
.clients_description {
	text-align: center;
}
.gem_client-carousel {
	margin-top: 25px;
}
.gem-client-prev {
	width: 10%;
}
.gem-client-next {
	width: 10%;
}
.gem-client-carousel-item-wrap {
	padding: 0 10%;
}
.widget .gem-teams-item {
	text-align: center;
	padding-top: 11px;
	padding-bottom: 40px;
}
.widget .gem-teams-name {
	padding-top: 56px;
}
.widget .gem-teams-position {
	font-size: 89.5%;
}

.widget .gem-teams-phone {
	text-transform: uppercase;
	padding-top: 28px;
	padding-bottom: 16px;
}
.widget .gem-teams-image img {
	margin: 0 auto;
	text-align: center;
	width: 128px;
}
.widget .gem-teams-image {
	display: block;
	padding-top: 50px;
	padding-bottom: 7px;
	height: 160px;
}
.widget  .team-person-email a:before{
	font-size: 34px;
}

.widget-teams {
	border-top-right-radius: 122px;
	border-top-left-radius: 122px;
	overflow: hidden;
}




.widget .team-person-email {
	font-size: 0px;
}
.widget .gem-teams-mail {
	padding-top: 3px;
}
.widget .gem-teams-mail a:before {
	padding-right: 10px;
	content: '\E607';
	font-family: 'thegem-icons';
	font-size: 22px;
	font-weight: normal;
	vertical-align: bottom;
}
.widget .gem-teams-name {
	padding-bottom: 8px;
}
.gem-team-style-horizontal .team-person-phone {
	padding-bottom: 0px;
	margin-bottom: -11px;
}
.gem-team-style-horizontal .team-person-phone:before {
	font-size: 22px;
}
.gem-team-style-horizontal .team-person-info {
	display: table-cell;
	vertical-align: middle;
	padding-left: 15px;
}
.gem-team-style-horizontal .team-person-image {
	display: table-cell;
	vertical-align: middle;
}
.gem-team-style-horizontal .team-person{
	padding-left: 30px;
	padding-top: 45px;
}

.gem-team-style-rounded .team-person-phone {
	padding-bottom: 8px;
	padding-top: 8px;
}
.gem-team-style-vertical .team-person-phone  {
	padding-bottom: 5px;
}
.fullwidth-block .container .gem-client-carousel-item-wrap {
	padding: 0 0%;
}
/*teams*/

/*twitter*/
.widget_tweets .twitter-box .jtwt_tweet {
	padding-bottom: 24px;
	padding-top: 27px;

}
body .widget_tweets .twitter-box  .jtwt_tweet:first-child {
	padding-top: 0px;
	background: none;
}
.jtwt_tweet:before {
	content: '\E611';
	float: left;
	font-family: thegem-icons;
	font-size: 18px;
	padding-right: 8px;
	position: relative;
	top: 0px;

}
.jtwt_date {
	padding-bottom: 12px;
	font-weight: 300;
}
.twitter-box .jtwt_tweet {
	padding: 0 0 0 0px;
}


.tweets-container {
	padding-top: 14px;
}
.jtwt_tweet_text {
	margin-bottom: 0px;
}
/*twitter*/


/*text*/
.gem-textbox-content blockquote {
	font-size: 116%;
}
.gem-textbox-content > blockquote {
	padding: 14px;
}
/*text*/

/*woocommerece widgets*/
.widget-area .gem-products-image,
.shop-widget-area .gem-products-image{
	display: table-cell;
}
ul.product_list_widget .gem-products-image a {
	position: relative;
	float: left;
}
ul.product_list_widget .gem-products-image a:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
	opacity: 0;
	z-index: 1;
	background: rgba(0, 188, 212, 0.3);
}
ul.product_list_widget .gem-products-image a:hover:before {
	opacity: 1;
}
.widget-area .gem-products-content,
.shop-widget-area .gem-products-content {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
}

.shop-widget-area .product_list_widget > li {
	display: inline-block;
	width: 100%;
}
.woocommerce ul.product_list_widget li img,
.woocommerce-page ul.product_list_widget li img {
	height: auto;
	width: 80px;
}
.woocommerce .widget_recently_viewed_products ul.product_list_widget li img,
.woocommerce-page .widget_recently_viewed_products ul.product_list_widget li img {
	height: 70px;
	width: 70px;
}
.shop-widget-area .gem-products-content {
	border-image: none;
	padding-bottom: 11px;
	position: relative;
	margin-left: 70px;
	padding-left: 18px;
}
ul.product_list_widget li {
	margin-bottom: 15px;
	display: inline-block;
	padding: 0;
	width: 100%;
}
.widget ul.product_list_widget li,
.widget_shopping_cart_content ul.product_list_widget li {
	overflow: visible;
}
ul.product_list_widget li:last-child {
	margin-bottom: 0px;
}
.widget .gem-products-content{
	margin-left: 70px;
	min-height: 70px;
	padding-left: 18px;
}
.widget .catalog-view .gem-products-content {
	padding-bottom: 0;
}
.shop-widget-area .gem-products-title {
	padding-top: 5px;
}
.widget .catalog-view .gem-products-title {
	padding-top: 0;
}
.shop-widget-area .gem-products-price {
	margin-bottom: -5px;
}
.widget .gem-products-price {
	font-size: 20px;
	line-height: 1.5em;
}
.woocommerce ul.product_list_widget li img,
.woocommerce-page ul.product_list_widget li img {
	margin-left: 0;
	box-shadow: none;
}
.shop-widget-area .gem-products-title a {
	padding-right: 80px;
}
.product_list_widget del {
	display: none;
}

.shop-widget-area .gem-products-rating {
	display: block;
	position: absolute;
	top: 13px;
	right: 20px;
}
.widget .catalog-view .gem-products-rating {
	position: relative;
	margin-top: 5px;
	top: auto;
	right: auto;
}
.widget .product_list_widget .onsale,
.shop-widget-area .onsale {
	position: absolute;
	width: 24px;
	height: 24px;
	line-height: 26px;
	border-radius: 50%;
	top: 13px;
	left: 68px;
	font-size: 0;
	z-index: 1;
	text-align: center;
	font-weight: inherit;
}
.widget .product_list_widget .onsale:after,
.shop-widget-area .onsale:after {
	content: '%';
	font-size: 16px;
}
.woocommerce .gem-products-price ins {
	font-size: 20px;
	text-decoration: none;
}
.gem-products-price > span {
	font-size: 20px;
	text-decoration: none;
}

.widget_product_search .screen-reader-text{
	display: none;
}
.widget_product_search input.search-field {
	width: 100%;
	padding-right: 35px;
	padding-left: 10px;
	box-sizing: border-box;
	line-height: 36px;
	border-width: 2px;
	border-radius: 2px;
}
.widget_product_search form {
	position: relative;
}
.widget_product_search button {
	background-color: transparent;
	font-family: 'thegem-icons';
	font-size: 24px;
	height: 36px;
	line-height: 36px;
	position: absolute;
	right: 2px;
	top: 7px;
	width: 36px;
	border: 0 none;
	background: transparent;

}

.widget_recent_reviews .product_list_widget li {
	padding-top: 5px;
}
.widget_recent_reviews .product_list_widget .attachment-woocommerce_thumbnail {
	float: left;
	margin-right: 10px;
	z-index: 100;
	margin-top: -5px;
}

.widget_black_studio_tinymce .wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required{
	width: 100%;
}
.widget_black_studio_tinymce .wpcf7-form-control.wpcf7-text {
	width: 100%;
}
.widget_black_studio_tinymce .wpcf7-form-control.wpcf7-textarea {
	width: 100%;
}
.widget_black_studio_tinymce div.wpcf7-mail-sent-ok {
	border: 2px solid #ACDD8B;
	margin: 0px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
}
.widget_black_studio_tinymce div.wpcf7-validation-errors {
	border: 2px solid #D55271;
	margin: 0px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
}

.widget_price_filter .price_label {
	float: right;
}
.widget_price_filter .price_slider_amount .button {
	float: left;
	margin: 0;
}
.widget_price_filter .price_slider_amount {
	padding-top: 15px;
}
.widget_price_filter .widget-title {
	padding-bottom: 15px;
}
.woocommerce .widget_price_filter .price_slider,
.woocommerce-page .widget_price_filter .price_slider {
	margin-bottom: 1em
}
.widget_price_filter .price_slider_wrapper .price_slider {
	height: 6px;
	overflow: visible;
	position: relative;
}
.widget_price_filter .price_slider .ui-slider-range {
	height: 100%;
	position: absolute;
}
.widget_price_filter .price_slider .ui-slider-handle {
	position: absolute;
	width: 14px;
	height: 14px;
	top: 50%;
	margin-top: -7px;
	margin-left: -7px;
	border-radius: 50%;
}
.widget-area .woocommerce .star-rating, .woocommerce-page .star-rating {
	float: none;
}
.widget-area .reviewer {
	display: block;
}
.widget-area .attachment-shop_thumbnail.wp-post-image,
.widget-area .attachment-woocommerce_thumbnail.wp-post-image {
	float: left;
}
.gem-products-content {
	margin-left: 80px;
}

.product_list_widget li{
	position: relative;
}
.widget-area .woocommerce-placeholder{
	float: left;
}
.widget_recent_entries ul li:last-child {
	border-bottom: 0 none;
}
.widget_recent_entries ul li:before {
	content: '\E63A';
	float: left;
	font-family: "thegem-icons";
	font-size: 24px;
	padding-left: 22px;
	padding-right: 6px;
	position: absolute;
	padding-top: 11px;
}
.widget_recent_entries ul li a {
	display: block;
	margin-left: 66px;
	padding-top: 9px;
	padding-right: 24px;
}
.widget_shopping_cart .variation {
	display: none;
}
.attachment-shop_thumbnail {
	width: 70px;
	height: 70px;
}
.widget .product-dummy,
.wpb_widgetised_column .widget .product-dummy{
	background-size: cover;
	float: left;
	margin-left: -1px;
	margin-top: -1px;
	width: 100%;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}
.widget .product-dummy-wrapper,
.wpb_widgetised_column .widget .product-dummy-wrapper{
	width: 70px;
	height: 70px;
	float: left;
}
.widget_recent_reviews .product-dummy-wrapper{
	margin-left: -1px;
	border-color: transparent;
}
.widget_shopping_cart .product_list_widget li a .product-dummy-wrapper {
	padding: 0px;

}

.shop-widget-area .widget .product-dummy {
	margin-left: -3px;
}
.widget_shopping_cart_content ul.product_list_widget > li img {
	margin: -1px;
}
.widget_product_search form {
	padding-top: 5px;
}
.customer_details > dt {
	font-weight: bold;
	float: left;
	padding-right: 10px;
}
.order-actions .gem-button {
	margin-top: 0px;
}
.widget_layered_nav .combobox-wrapper {
	height: 40px;
	border-width: 2px;
}
.widget_layered_nav .combobox-wrapper .combobox-text {
	line-height: 36px;
}
.widget_layered_nav .combobox-wrapper .combobox-button {
	width: 36px;
	height: 36px;
}
.widget_layered_nav ul{
	margin: 0;
	padding: 0;
}
.widget_layered_nav ul li {
	list-style: none;
	padding: 12px 0 12px 15px;
}
.widget_layered_nav ul li:first-child {
	border-top: 0 none;
}
.widget_layered_nav ul li a:before {
	content: "\E601";
	font-family: "thegem-icons";
	font-size: 15px;
	font-weight: normal;
	margin-right: 8px;
	position: relative;
	top: 1px;
}

.widget_layered_nav ul li small:before {
	content: "(";
}
.widget_layered_nav ul li small:after {
	content: ")";
}
.adress-button {
	margin-bottom: 30px;
}

/*woocommerece widgets*/

/*pricing tables*/


/*pricing tables*/



/*top-area*/
.top-area-contacts .gem-contacts {
	margin: 0 -17px;
}
.top-area-contacts .gem-contacts-item {
	display: inline-block;
	font-size: 14px;
	padding: 0 17px;
	line-height: 30px;
	vertical-align: top;
}
.top-area-contacts .gem-contacts-item.gem-contacts-phone a {
	color: inherit;
}
.top-area-contacts .gem-contacts-address:before,
.top-area-contacts .gem-contacts-phone:before,
.top-area-contacts .gem-contacts-fax:before,
.top-area-contacts .gem-contacts-email:before,
.top-area-contacts .gem-contacts-website:before {
	font-family: 'thegem-icons';
	margin-right: 0.33em;
	vertical-align: top;
	display: inline-block;
	width: 16px;
	font-size: 16px;
	text-align: center;
}
.top-area-contacts .gem-contacts-address:before {
	content: '\E604';
}
.top-area-contacts .gem-contacts-phone:before {
	content: '\E625';
}
.top-area-contacts .gem-contacts-fax:before {
	content: '\E606';
}
.top-area-contacts .gem-contacts-email:before {
	content: '\E607';
}

/*top-area*/

/*styles*/
.gem_accordion_header {
	outline: none;
}
.gem-news-carousel-wrap .gem-news-pagination{
	padding-top: 35px;
}
.gem_accordion_header {
	cursor: pointer;
}
.gem_accordion_header a {
	text-transform: uppercase;
}

.post-author-block {
	display: block;
	padding: 40px;
}
.post-author-avatar {
	display: block;
	float: left;
}
a.post-author-avatar:after {
	display: block;
	text-align: center;
	content: '\E607';
	font-family: 'thegem-icons';
	font-weight: normal;
	font-size: 32px;
	padding-top: 25px;
}
.post-author-avatar img {
	border-radius: 50%;
	-webkit-border-radius:  50%;
	-moz-border-radius: 50%;

}
.post-author-info {
	margin-left: 135px;
	margin-top: 0;
}
.post-author-info .name {
	margin-top: 0;
}

.post-author-posts-link a:before {
	content: '\E601';
	font-family: 'thegem-icons';
	font-weight: normal;
	vertical-align: top;
	margin-right: 5px;
	font-size: 16px;
}
@media (max-width: 599px) {
	.post-author-avatar {
		float: none;
		margin-bottom: 25px
	}
	.post-author-info {
		margin-left: 0;
		margin-top: 0;
	}
	.post-author-info .name {
		margin-top: 0;
	}
	a.post-author-avatar:after {
		display: inline-block;
		vertical-align: middle;
		padding-top: 0;
		padding-left: 25px;
		line-height: 1;
	}
}

.post-author-info .date {
	margin-left: 23px;
}
.post-author-description {
	margin-bottom: 20px;
}

.read-more-link {
	float: right;
}
.byline {
	padding: 0 5px;
}
.entry-meta {
	margin-bottom: 8px;
}
.sep {
	margin: 0 6px;
	border-left: 1px solid #d4dee1;
	height: 20px;
	display: inline-block;
	vertical-align: middle;
}
.read-more-link:before {
	content: '\E601';
	font-family: thegem-icons;
	padding-left: -20px;
	font-size: 14px;
}

/* DEFAULT BLOG */
.post-title a {
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.post-meta-right {
	float: right;
	font-size: 16px;
	white-space: nowrap;
}
.single-post-meta .post-meta-right {
	margin-left: 100px;
}
.post-meta-right > * {
	display: inline-block;
	vertical-align: top;
}
.post-meta-right > .sep {
	vertical-align: middle;
}
.comments-link a {
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.comments-link a:before {
	content: '\E61E';
	font-family: 'thegem-icons';
	font-size: 14px;
	font-weight: normal;
	margin-right: 6px;
	display: inline-block;
	line-height: inherit;
}
.post-footer-sharing {
	display: inline-block;
	position: relative;
}
.sharing-popup {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	bottom: 50px;
	border-radius: 25px;
	height: 50px;
	line-height: 50px;
	padding: 0 25px;
	white-space: nowrap;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	left: -20px;
	margin-bottom: -10px;
}
.sharing-popup .socials {
	line-height: 16px;
	height: 16px;
	margin-top: 17px;
}
article.sticky .sharing-popup {
	bottom: 70px;
	left: -10px;
}
.sharing-popup .sharing-styled-arrow {
	position: absolute;
	bottom: -100px;
	height: 100px;
	width: 50px;
	left: -30px;
	-ms-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 100% 0;
	-o-transform: rotate(-90deg) scale(0.3);
	-ms-transform: rotate(-90deg) scale(0.3);
	-moz-transform: rotate(-90deg) scale(0.3);
	transform: rotate(-90deg) scale(0.3);
}
.sharing-popup.active {
	visibility: visible;
	opacity: 1;
	margin-bottom: 0;
}
.sharing-popup a.socials-item {
	vertical-align: top;
}
.post-footer-sharing .gem-button-container .gem-button {
	margin-right: 13px;
}
.post-footer .gem-button-container .gem-button {
	margin-bottom: 0;
}
article.sticky .post-footer-sharing .gem-button-container .gem-button {
	margin-right: 16px;
}
.post-read-more {
	display: inline-block;
}
.post-read-more .gem-button,
.comment-reply-link {
	padding: 0 24px;
	background: transparent;
}



.styled-blog-meta {
	display: inline-block;
	float: right;
}
.styled-blog-meta .comments {
	padding-right: 8px;
	float: left;
}
.styled-blog-meta .comments:after  {
	content: "|";
	padding-left: 8px;
	vertical-align: top;
}

.styled-blog-meta .tag-links {
	display: none;
}
.styled-blog-meta {
	bottom: 27px;
	position: absolute;
	right: 32px;
}


article:not(.type-page) > .entry-content.post-content {
	margin-bottom: 60px;
}
.post-content .post-meta {
	margin-bottom: 55px;
}

/* Post tags */
.post-tags-list {
	margin-bottom: 30px;
	margin-left: 10px;
}
.post-tags-list a {
	position: relative;
	display: inline-block;
	color: inherit;
	margin-right: 17px;
	margin-bottom: 10px;
	font-size: 13px;
	line-height: 21px;
	background-color: #e7edf0;
	z-index: 2;
	padding: 0 16px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	-o-transition: all 0.2s;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}
.post-tags-list a:before {
	content: '';
	position: absolute;
	background-color: inherit;
	width: 12px;
	height: 12px;
	top: 5px;
	left: -5px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	z-index: -1;
	transform: skew(-45deg, 45deg);
}
.post-tags-list a:after {
	content: '';
	position: absolute;
	width: 6px;
	height: 6px;
	top: 50%;
	margin-top: -3px;
	left: -5px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}



.block-tags .tags-links a{
	padding: 0 10px;
}
.block-tags {
	float: right;
	display: block;
	padding-top: 21px;
}
.block-date {
	float: left;
	font-size: 89.5%;
	margin-top: 2px;
	margin-right: 10px;
}

.post-meta-categories a {
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
@media (max-width: 755px) {
	.single-post-meta .post-meta-categories {
		display: block;
		clear: both;
	}
	.single-post-meta .post-meta-author + .sep,
	.single-post-meta .post-meta-categories + .sep {
		display: none;
	}
	.single-post-meta .post-meta-right {
		margin-left: 30px;
	}
}

.thegem_pf_item .post-meta-left .post-meta-date:before {
	content: '\E66E';
	font-family: 'thegem-icons';
	font-size: 16px;
	font-weight: normal;
	margin-right: 8px;
}

.post-meta-navigation {
	font-family: 'thegem-icons';
	font-weight: normal;
	font-size: 24px;
	white-space: nowrap;
	vertical-align: top;
	margin-left: 10px;
}
.post-meta-navigation > span + span {
	margin-left: -7px;
}
.thegem_pf_item .post-meta-navigation > span + span {
	margin-left: -10px;
}
.post-meta-navigation a {
	color: inherit;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.thegem_pf_item .post-meta-navigation > .post-meta-category-link a {
	font-size: 16px;
	vertical-align: top;
}

.block-navigation {
	clear: both;
	display: inline-block;
	width: 100%;
}
.block-navigation-prev {
	float: left;
}
.block-navigation-prev a:before {
	content: '\E603';
	font-size: 15px;
	padding-right: 10px;
	font-family: thegem-icons;
	display: block;
	float: left;
}
.block-navigation-next {
	float: right;
}
.block-navigation-next a:after {
	content: '\E601';
	font-size: 15px;
	padding-left: 10px;
	font-family: thegem-icons;
	display: block;
	float: right;
}
.block-divider {
	margin-top: 50px;
	display: block;
	margin-bottom: 45px;
}
.block-thegem_socials_sharing {
	width: 100%;
	display: inline-block;
}
.portfolio-item-page-bottom .socials-sharing .socials-item-icon {
	font-size: 24px;
}
.portfolio-item-page-bottom .socials-sharing {
	margin-top: 42px;
	float: left;
}
.portfolio-item-page-bottom .centered-box .socials-sharing {
	float: none;
}

.portfolio-item-page-bottom .project-button .gem-button {
	margin-top: 40px;
}

.block-portfolio-navigation-prev .gem-button {
	padding-left: 3px;
	padding-right: 14px;
}
.block-portfolio-navigation-prev .gem-button .gem-print-icon {
	margin-right: 3px;
}
.block-portfolio-navigation-next .gem-button {
	padding-right: 3px;
	padding-left: 14px;
}
.block-portfolio-navigation-next .gem-button .gem-print-icon {
	margin-left: 3px;
}

.block-navigation.centered-box .block-portfolio-navigation-prev .gem-button,
.block-navigation.centered-box .block-portfolio-navigation-next .gem-button {
	margin-left: 30px;
	margin-right: 30px;
}

.post-related-posts {
	padding-bottom: 50px;
}
.post-related-posts-block {
	margin: 0 -21px;
}
.post-related-posts-block .related-element {
	float: left;
	padding: 0;
	width: 303px;
	padding: 0 21px;
}
.post-related-posts-block .related-element .entry-meta {
	margin-bottom: 0;
}

.related-posts-navigation {
	position: absolute;
	top: -85px;
	right: 21px;
}
.related-posts-navigation .related-posts-prev,
.related-posts-navigation .related-posts-next {
	height: 30px;
	width: 30px;
	padding: 0;
	border: 2px solid #00bcd4;
	background: transparent;
	margin-left: 15px;
}
.related-posts-navigation .gem-button.related-posts-prev:hover,
.related-posts-navigation .gem-button.related-posts-next:hover {
	background-color: #3c3950;
	border-color: #3c3950;
}
.related-posts-carousel {
	position: relative;
}
.related-element img,
.related-element .gem-dummy {
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	height: 72px;
	width: 72px;
}
.related-element .post-meta-right {
	font: inherit;
}
.related-element > a {
	display: table-cell;
	padding-right: 14px;
}
.related-element .post-meta-right > * {
	display: inline-block;
	vertical-align: top;
}
.related-element .post-meta-right .zilla-likes:before {
	display: inline-block;
}
.related-element .post-meta-right .zilla-likes > * {
	display: inline-block;
	vertical-align: top;
}
.related-element .comments-link a {
	display: inline-block;
	vertical-align: top;
	padding-right: 5px;
}
.related-element .comments-link a:before {
	display: inline-block;
}
.related-element-info {
	display: table-cell;
	vertical-align: middle;
	width: 99%;
}
.related-element-info > a {
	margin-bottom: 3px;
}
.related-element-info .date {
	display: block;
	margin-top: -2px;
}
.related-element-info .comments-link a::before {
	content: '\E631';
}
.related-element-info-conteiner {
	max-height: 3.1em;
	overflow: hidden;
}

.post-related-posts-bloack {
	margin-top: 8px;
}
.post-related-posts h3 {
	text-transform: uppercase;
}
.related-element {
	padding: 30px 0 30px 30px;
}
.post-related-posts {
	margin-top: 75px;
}
.post-author-block {
	margin-top: 85px;
}
.wpb_wrapper .vc_progress_bar .vc_label {
	font-size: 89.5%;
}
.wpb_wrapper .vc_progress_bar .vc_label_units {
	font-size: 116%;
	padding-left: 8px;
}

.gem-btn-primary {
	color: #0088cc;
}
.gem-btn-warning {
	color: #ff9900;
}
.gem-btn-danger {
	color: #ff675b;
}
.gem-btn-success {
	color: #6ab165;
}
.gem-btn-info {
	color: #58b9da;
}
.gem-btn-inverse {
	color: #555555;
}

#customer_login .login label,
#customer_login .register label {
	display: block;
}
#customer_login .login,
#customer_login .register {
	padding: 33px;
	padding-right: 10px;
	width: 100%;
	display: inline-block;
}
#customer_login .login .input-text, #customer_login .register .input-text{
	width: 97%;
	margin-top: 5px;
	padding: 4px 10px;
}
#customer_login .login .inline {
	display: inline-block;
}
#customer_login .form-row.login-button {
	display: inline-block;
}
#customer_login .lost_password {
	display: inline-block;
}

.inline {
	display: block;
}

#customer_login .rememberme-label {
	padding-left: 9px;
}
#customer_login .lost_password {
	padding-left: 19px;
}
#customer_login .login-button button{
	top: 8px;
}
#customer_login .form-row.login-button {
	padding-left: 18px;
}
#customer_login .form-row.register_form_button {
	display: inline-block;
	float: right;
	margin-right: 15px;
}
#customer_login .form-row.register_form_button button{
	top: 8px;
}
#customer_login .login  .gem-button input[type="submit"] {
	padding: 0.6em 32px 0.6em 50px;
}
.account-tittle{
	text-transform: uppercase;
}
.lost_reset_password .input-text{
	margin-right: 20px;
	margin-top: 5px;
	padding-left: 10px;
	width: 36%;
}
.lost_reset_password .gem-button {
	margin-top: -5px;
}
.lost_reset_password .input-text {
	float: left;
}
.form-edit-adress label {
	display: block;
	padding-bottom: 5px;
}
.form-edit-adress abbr {
	border: 0 none;
}
.form-edit-adress input {
	width: 50%;
}
.form-edit-adress  .button  {
	width: auto;
}
.isotope-item {
	padding-bottom: 10px;
}
.gem-post-grid-item-image {
	display: table-cell;

}
.gem-post-grid-item-image img {
	width: 72px;
	height: 72px;
}
.gem-post-grid-item-info {
	display: table-cell;
	vertical-align: middle;
	padding-left: 20px;
}
.gem-post-griid-item.clearix {
	padding-bottom: 30px;
}
.gem-post-grid-item-date {
	font-size: 89.5%;
}
/*styles*/
@media (max-width: 768px) {
	.wpcf7-form td {
		float: left;
		width: 100%;
		text-align: center;
	}
}

/* MAIL CHIMP */
.site-footer .gem-custom-mailchimp input {
	border: none;
}
#colophon .gem-custom-mailchimp input[type="email"] {
	width: 50%;
	height: 54px;
	border-top-right-radius: 0;
	-moz-border-top-right-radius: 0;
	-webkit-border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	-moz-border-bottom-right-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	margin-top: 25px;
}
#colophon .gem-custom-mailchimp button {
	margin-top: 25px;
	border-top-left-radius: 0;
	-moz-border-top-left-radius: 0;
	-webkit-border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	-moz-border-bottom-left-radius: 0;
	-webkit-border-bottom-left-radius: 0;
}

#colophon .widget .gem-testimonial-position,
#colophon .widget .gem-pp-posts-date,
#colophon .widget .jtwt_date,
#colophon .widget .gem-latest-news-title > span,
#colophon .widget.widget_recent_entries .post-date {
	font-size: 89.5%;
}
.gem-latest-news:last-child{
	border-bottom: none;
}
/* MENU WIDGET */


.widget_nav_menu li li a:before,
.widget_submenu li li a:before
{
	content: '';
	width: 6px;
	display: block;
	height: 6px;
	position: absolute;
	margin-top: 10px;
	margin-left: -20px;
}
.widget_pages li li a:before,
.widget_categories li li a:before,
.widget_product_categories li li a:before{
	content: '';
	width: 6px;
	display: block;
	height: 6px;
	float: left;
	margin-top: 10px;
	margin-right: 12px;
}
.widget_pages a {
	display: block;
}
.widget_nav_menu ul,
.widget_categories ul,
.widget_product_categories ul,
.widget_pages ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.widget_nav_menu > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a,
.widget_pages > ul > li > a {
	padding-left: 18px;
}



.widget_nav_menu ul.menu li > a,
.widget_submenu > div > ul li > a {
	display: block;
	padding: 12px 12px 12px 20px;
}
.widget_categories > ul > li,
.widget_product_categories > ul > li,
.widget_pages > ul > li {
	padding: 12px 12px 12px 0;
}
.widget_categories > ul li > a,
.widget_product_categories > ul li > a,
.widget_pages > ul li > a {
}
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a,
.widget_pages > ul > li > a {
	text-transform: uppercase;
}



.widget_nav_menu ul.menu li > a:after,
.widget_submenu > div > ul li > a:after {
	content: '';
	display: inline-block;
	width: 30px;
	vertical-align: top;
	margin-left: -30px;
	text-align: center;
}

.widget_nav_menu ul.menu ul,
.widget_submenu > div > ul ul {
	display: none;
}
.widget_categories > ul > li > ul,
.widget_product_categories > ul > li > ul,
.widget_pages > ul > li > ul {
	margin: 12px -12px -12px 0;
}
.widget_nav_menu ul.menu li.menu-item-active > ul,
.widget_nav_menu ul.menu li.menu-item-current > ul,
.widget_submenu > div > ul li.menu-item-active > ul,
.widget_submenu > div > ul li.menu-item-current > ul {
	display: block;
}
.widget_nav_menu ul.menu li.menu-item-parent > a:after,
.widget_submenu > div > ul li.menu-item-parent > a:after,
.widget_categories > ul li.cat-parent:after,
.widget_product_categories > ul li.cat-parent:after,
.widget_pages > ul li.page_item_has_children:after {
	font-weight: normal;
}
.widget_nav_menu ul.menu li.menu-item-parent.menu-item-active > a:after,
.widget_nav_menu ul.menu li.menu-item-parent.menu-item-current > a:after,
.widget_submenu > div > ul li.menu-item-parent.menu-item-active > a:after,
.widget_submenu > div > ul li.menu-item-parent.menu-item-current > a:after,
.widget_product_categories > ul li.cat-parent.current-cat-ancestor:after,
.widget_product_categories > ul li.cat-parent.current-cat-parent:after,
.widget_product_categories > ul li.cat-parent.current-cat:after{

	content: '\E635';
	font-family: thegem-icons;
	font-size: 16px;
	font-weight: normal;

}
.widget_nav_menu ul.menu li li.menu-item-parent.menu-item-active > a:after,
.widget_nav_menu ul.menu li li.menu-item-parent.menu-item-current > a:after,
.widget_submenu > div > ul li li.menu-item-parent.menu-item-active > a:after,
.widget_submenu > div > ul li li.menu-item-parent.menu-item-current > a:after,
.widget_categories > ul li li.cat-parent.current-cat-ancestor:after,
.widget_categories > ul li li.cat-parent.current-cat-parent:after,
.widget_categories > ul li li.cat-parent.current-cat:after,
.widget_product_categories > ul li li.cat-parent.current-cat-ancestor:after,
.widget_product_categories > ul li li.cat-parent.current-cat-parent:after,
.widget_product_categories > ul li li.cat-parent.current-cat:after {

	content: '\E635';
	font-family: thegem-icons;
	font-size: 16px;
	font-weight: normal;

}
.widget .menu-item-has-children.menu-item-parent > a:after {
	content: '\E601';
	font-size: 16px;
	font-family: thegem-icons;
	font-weight: normal;

}

.widget_nav_menu ul.menu > li.menu-item-parent.menu-item-active > a:after,
.widget_nav_menu ul.menu > li.menu-item-parent.menu-item-current > a:after,
.widget_submenu > div > ul > li.menu-item-parent.menu-item-active > a:after,
.widget_submenu > div > ul > li.menu-item-parent.menu-item-current > a:after,
.widget_product_categories > ul > li.cat-parent.current-cat-ancestor:after,
.widget_product_categories > ul > li.cat-parent.current-cat-parent:after,
.widget_product_categories > ul > li.cat-parent.current-cat:after {
	font-size: 24px;
}

.widget_categories > ul li.cat-parent.current-cat-ancestor:after,
.widget_categories > ul li.cat-parent.current-cat-parent:after,
.widget_categories > ul li.cat-parent.current-cat:after,
.widget_product_categories > ul li.cat-parent.current-cat-ancestor:after,
.widget_product_categories > ul li.cat-parent.current-cat-parent:after,
.widget_product_categories > ul li.cat-parent.current-cat:after {
	content: '\E602';
	font-family: thegem-icons;
	font-size: 16px;
	top: 12px;
	right: 18px;
	position: absolute;
	font-weight: normal;

}
.widget_pages > ul li.page_item_has_children.current_page_ancestor,
.widget_pages > ul li.page_item_has_children.current_page_parent,
.widget_pages > ul li.current_page_item,
.widget_categories > ul li.cat-parent.current-cat-ancestor,
.widget_categories > ul li.cat-parent.current-cat-parent,
.widget_categories > ul li.cat-parent.current-cat,
.widget_product_categories > ul li.cat-parent.current-cat-ancestor,
.widget_product_categories > ul li.cat-parent.current-cat-parent,
.widget_product_categories > ul li.cat-parent.current-cat {
	position: relative;
}


.widget_pages > ul li.page_item_has_children.current_page_ancestor > a:after,
.widget_pages > ul li.page_item_has_children.current_page_parent > a:after,
.widget_pages > ul li.page_item_has_children.current_page_item > a:after {
	content: '\E602';
	font-family: thegem-icons;
	font-size: 13px;
	top: 12px;
	right: 18px;
	position: absolute;
	font-weight: normal;

}
.widget_pages > ul li li.page_item_has_children.current_page_ancestor > a:after,
.widget_pages > ul li li.page_item_has_children.current_page_parent > a:after,
.widget_pages > ul li li.page_item_has_children.current_page_item > a:after {
	content: '\E602';
	font-family: thegem-icons;
	font-size: 8px;
	top: 12px;
	right: 18px;
	position: absolute;
	font-weight: normal;

}

.widget_nav_menu ul.menu ul li.menu-item-active > a,
.widget_submenu > div > ul ul li.menu-item-active > a {
	border: 0 none !important;
}
.widget_nav_menu ul.menu > li li > a,
.widget_submenu > div > ul > li li > a {
	padding-left: 40px;
}
.widget_nav_menu ul.menu > li li li > a,
.widget_submenu > div > ul > li li li > a {
	padding-left: 60px;
}
.widget_nav_menu ul.menu > li li li li > a,
.widget_submenu > div > ul > li li li li > a {
	padding-left: 80px;
}
.widget_nav_menu ul.menu > li li li li li > a,
.widget_submenu > div > ul > li li li li li > a {
	padding-left: 100px;
}
.widget_categories > ul > li > ul > li,
.widget_product_categories > ul > li > ul > li,
.widget_pages > ul > li > ul > li {
	padding: 12px 12px 12px 20px;
}
.widget_categories > ul > li > ul > li > ul,
.widget_product_categories > ul > li > ul > li > ul,
.widget_pages > ul > li > ul > li > ul {
	margin: 12px -12px -12px -20px;
}
.widget_categories > ul > li > ul > li li,
.widget_product_categories > ul > li > ul > li li,
.widget_pages > ul > li > ul > li li {
	padding: 10px 15px 10px 40px;
}
.widget_categories > ul > li > ul > li > ul ul,
.widget_product_categories > ul > li > ul > li > ul ul,
.widget_pages > ul > li > ul > li > ul ul {
	margin: 10px -15px -10px -20px;
}
.widget_pages > ul > ul {
	padding-left: 30px;
}
#colophon .widget_submenu a,
#colophon .widget_nav_menu a {
	background: transparent;
}

/* Recently Viewed Products */
.widget_recently_viewed_products .product_list_widget a {
	clear: both;
}
.widget_recently_viewed_products ul.product_list_widget li {
	padding: 10px;
	margin-bottom: 0;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-moz-box-shadow: none;
	display: block;
}
.widget_recently_viewed_products ul.product_list_widget li + li {
	background-repeat: repeat-x;
}
.widget_recently_viewed_products ul.product_list_widget li .gem-products-image {
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	overflow: hidden;
}
.widget_recently_viewed_products ul.product_list_widget li .gem-products-content {
	border: 0 none;
}
.widget_recently_viewed_products ul.product_list_widget li .product-dummy-wrapper {
	border: 0 none;
}



/* gem_client_carousel*/
.gem_client_carousel-items .gem-client-item .grayscale-hover {
	background: none repeat scroll 0 0 transparent !important;
}
.teaser_grid_container .gem-post-title {
	padding-right: 31px;
}
body .footer-contact-form .wpcf7-email {
	background-color: #2c2e3a;
	border: medium none;
	color: #ffffff;
	height: 54px;

}
body .footer-contact-form .wpcf7-textarea {
	background-color: #2c2e3a;
	border: medium none;
	color: #ffffff;
	height: 117px;

}
.footer-contact-form {
	padding-right: 30px;
}
.footer-contact-form .wpcf7-submit{
	background-color: #4c5867;
	float: left;
}
.footer-contact-form .wpcf7-submit:hover{
	background: none repeat scroll 0 0 rgba(255, 255, 255, 0.2);
}
.widget .wpcf7 .gem-sidebar-input {
	text-align: left;
}

.wpb_widgetised_column .gem-products-image {
	display: table-cell;
}
.wpb_widgetised_column .gem-products-content {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
}
.widget .blog {
	padding-bottom: 0px;
}


.widget-title:after{
	font-family: thegem-icons;
	content: '\E630';
	font-size: 8px;
	padding-left: 8px;
	font-weight: normal;

}
#colophon .gem-latest-news:last-child,
#colophon .Custom_Recent_Posts .gem-pp-posts:first-child,
#colophon .widget_meta li:first-child,
#colophon .widget_rss li:first-child,
#colophon .widget_recent_entries li:first-child,
#colophon .Custom_Recent_Posts ul li:first-child {
background: none;
}
.digram-line-box {
	padding-right: 40px;
}

/* Gem icon hexagon*/
.gem-icon-shape-hexagon {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	border: 0;
	position: relative;
}

.gem-icon-shape-hexagon .gem-icon-shape-hexagon-back {
	display: block;
	-webkit-transform: rotate(-30deg) skewX(30deg);
	-ms-transform: rotate(-30deg) skewX(30deg);
	-o-transform: rotate(-30deg) skewX(30deg);
	transform: rotate(-30deg) skewX(30deg);
	overflow: hidden;
	position: relative;
	z-index: 1;
	font-size: 0;
	line-height: 1;
}

.gem-icon-shape-hexagon .gem-icon-shape-hexagon-back .gem-icon-shape-hexagon-back-inner,
.gem-icon-shape-hexagon .gem-icon-shape-hexagon-back .gem-icon-shape-hexagon-back-inner-before {
	display: block;
	width: inherit;
	height: inherit;
	-webkit-border-radius: inherit;
	-moz-border-radius: inherit;
	border-radius: inherit;
	overflow: hidden;
}

.gem-icon-shape-hexagon .gem-icon-shape-hexagon-back .gem-icon-shape-hexagon-back-inner {
	-webkit-transform: skewX(-30deg) rotate(60deg) skewX(30deg);
	-ms-transform: skewX(-30deg) rotate(60deg) skewX(30deg);
	-o-transform: skewX(-30deg) rotate(60deg) skewX(30deg);
	transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.gem-icon-shape-hexagon .gem-icon-shape-hexagon-back .gem-icon-shape-hexagon-back-inner-before {
	-webkit-transform: skewX(-30deg) rotate(60deg) skewX(30deg);
	-ms-transform: skewX(-30deg) rotate(60deg) skewX(30deg);
	-o-transform: skewX(-30deg) rotate(60deg) skewX(30deg);
	transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.gem-icon-shape-hexagon .gem-icon-shape-hexagon-top {
	display: block;
	-webkit-transform: rotate(-30deg) skewX(30deg);
	-ms-transform: rotate(-30deg) skewX(30deg);
	-o-transform: rotate(-30deg) skewX(30deg);
	transform: rotate(-30deg) skewX(30deg);
	overflow: hidden;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	font-size: 0;
	line-height: 1;
}

.gem-icon-shape-hexagon .gem-icon-shape-hexagon-top .gem-icon-shape-hexagon-top-inner,
.gem-icon-shape-hexagon .gem-icon-shape-hexagon-top .gem-icon-shape-hexagon-top-inner-before {
	display: block;
	width: inherit;
	height: inherit;
	-webkit-border-radius: inherit;
	-moz-border-radius: inherit;
	border-radius: inherit;
	overflow: hidden;
}

.gem-icon-shape-hexagon .gem-icon-shape-hexagon-top .gem-icon-shape-hexagon-top-inner {
	-webkit-transform: skewX(-30deg) rotate(60deg) skewX(30deg);
	-ms-transform: skewX(-30deg) rotate(60deg) skewX(30deg);
	-o-transform: skewX(-30deg) rotate(60deg) skewX(30deg);
	transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.gem-icon-shape-hexagon .gem-icon-shape-hexagon-top .gem-icon-shape-hexagon-top-inner-before {
	-webkit-transform: skewX(-30deg) rotate(60deg) skewX(30deg);
	-ms-transform: skewX(-30deg) rotate(60deg) skewX(30deg);
	-o-transform: skewX(-30deg) rotate(60deg) skewX(30deg);
	transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.gem-icon-shape-hexagon .gem-icon-inner {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	background: none !important;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 3;
	width: 100%;
	height: 100%;
	margin: 0;
}

/* Hexagon small icon */
.gem-icon-shape-hexagon.gem-icon-size-small,
.wpb_column > .wpb_wrapper > .gem-icon-shape-hexagon.gem-icon-size-small:last-child {
	margin: 3px 0;
}

.gem-icon-shape-hexagon.gem-icon-size-small .gem-icon-shape-hexagon-back {
	width: 59px;
	height: 49px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.gem-icon-shape-hexagon.gem-icon-size-small .gem-icon-shape-hexagon-top {
	width: 55px;
	height: 45px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	left: 2px;
	top: 2px;
}

.gem-icon-shape-hexagon.gem-icon-size-small .gem-icon-inner {
	line-height: 49px;
}

/* Hexagon medium icon */
.gem-icon-shape-hexagon.gem-icon-size-medium,
.wpb_column > .wpb_wrapper > .gem-icon-shape-hexagon.gem-icon-size-medium:last-child {
	margin: 5px 0;
}

.gem-icon-shape-hexagon.gem-icon-size-medium .gem-icon-shape-hexagon-back {
	width: 91px;
	height: 77px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
}

.gem-icon-shape-hexagon.gem-icon-size-medium .gem-icon-shape-hexagon-top {
	width: 85px;
	height: 71px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
	left: 3px;
	top: 3px;
}

.gem-icon-shape-hexagon.gem-icon-size-medium .gem-icon-inner {
	line-height: 77px;
}

/* Hexagon large icon */
.gem-icon-shape-hexagon.gem-icon-size-large,
.wpb_column > .wpb_wrapper > .gem-icon-shape-hexagon.gem-icon-size-large:last-child {
	margin: 11px 0;
}

.gem-icon-shape-hexagon.gem-icon-size-large .gem-icon-shape-hexagon-back {
	width: 181px;
	height: 153px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.gem-icon-shape-hexagon.gem-icon-size-large .gem-icon-shape-hexagon-top {
	width: 169px;
	height: 141px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	left: 6px;
	top: 6px;
}

.gem-icon-shape-hexagon.gem-icon-size-large .gem-icon-inner {
	line-height: 153px;
}

/* Hexagon xlarge icon */
.gem-icon-shape-hexagon.gem-icon-size-xlarge,
.wpb_column > .wpb_wrapper > .gem-icon-shape-hexagon.gem-icon-size-xlarge:last-child {
	margin: 16px 0;
}

.gem-icon-shape-hexagon.gem-icon-size-xlarge .gem-icon-shape-hexagon-back {
	width: 267px;
	height: 227px;
	-webkit-border-radius: 14px;
	-moz-border-radius: 14px;
	border-radius: 12px;
}

.gem-icon-shape-hexagon.gem-icon-size-xlarge .gem-icon-shape-hexagon-top {
	width: 243px;
	height: 203px;
	-webkit-border-radius: 14px;
	-moz-border-radius: 14px;
	border-radius: 14px;
	left: 12px;
	top: 12px;
}

.gem-icon-shape-hexagon.gem-icon-size-xlarge .gem-icon-inner {
	line-height: 227px;
}


.widget_socials .socials-item-icon {
	font-size: 24px;
	margin-bottom: 13px;
}
.widget_socials a.socials-item {
	margin-left: 0;
	margin-right: 13px;
}

.widget .socials-colored a:hover .socials-item-icon{
	color: #00bcd4 !important;
}
.widget .gem-portfolios-type-carousel-grid .gem-portfolio-item {
	padding: 0px 12px 12px 0;
	display: block;
}
.widget-portfolio-carousel-slide a{
	display: block;
	height: 80px;
	float: left;
	margin-right: 10px;
	margin-bottom: 10px;
	overflow: hidden;
}
.widget-portfolio-carousel-slide img{
	width: 80px;
	height: 80px;
	display: block;
}
.widget-portfolio-carousel-slide {
	float: left;
}
.widget-portfolio-carousel-slide {
	margin-right: -10px;
}
.widget .widget-portfolio-pagination.gem-mini-pagination a {
	width: 14px;
	height: 14px;
}
.widget .widget-portfolio-pagination.gem-mini-pagination {
	padding-top: 4px;
}
.widget-gem-portfolio-item.gem-portfolio-dummy img{
	display: none;
}
.widget-gem-portfolio-item.gem-portfolio-dummy {
	position: relative;
}
.widget-gem-portfolio-item.gem-portfolio-dummy:after {
	content: '?';
	color: #ffffff;
	position: absolute;
	left: 35px;
	top: 28px;
}

.widget-gem-portfolio-item	{
	position: relative;
}
.widget-gem-portfolio-item img {
	transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
}

.widget-gem-portfolio-item .widget-gem-portfolio-item-hover {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 5;
	opacity: 0;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.widget-gem-portfolio-item:hover .widget-gem-portfolio-item-hover {
	opacity: 1;
}
.project_info-item .icon-elegant,
.project-info-shortcode-item .icon.elegant {
	font-family: 'ElegantIcons';
}
.project_info-item .icon-material,
.project-info-shortcode-item .icon.material {
	font-family: 'MaterialDesignIcons';
}
.project_info-item .icon-fontawesome,
.project-info-shortcode-item .icon.fontawesome {
	font-family: 'FontAwesome';
}
.project_info-item .icon-userpack,
.project-info-shortcode-item .icon.userpack {
	font-family: 'UserPack';
}
.project-info-shortcode-item .icon {
	float: left;
}
.project-info-shortcode-item {
	width: 100%;
	float: left;
}
.project-info-shortcode-style-2 .project-info-shortcode-item .title {
	font-size: 16px;
	font-weight: bolder;
	padding-left: 70px;
	color: #3c3950;
	text-transform: uppercase;
}
.project-info-shortcode-style-2 .project-info-shortcode-item .decription {
	padding-left: 70px;
	padding-top: 3px;
}
.project-info-shortcode-style-2 .project-info-shortcode-item .icon {
	color: #ffffff !important;
	font-size: 24px;
	padding: 13px;
}
.project-info-shortcode-style-2 .project-info-shortcode-item {
	padding: 28px 0;
}
.project-info-shortcode-style-2 .project-info-shortcode-item:last-child{
	border-bottom: medium none;
}
.project-info-shortcode {
	display: inline-block;
	width: 100%;
}

.project-info-shortcode-style-default .project-info-shortcode-item {
	margin-bottom: 20px;
	padding: 20px;
}
.project-info-shortcode-style-default .project-info-shortcode-item .icon {
	background-color: transparent !important;
	font-size: 24px;
	padding-top: 6px;
	padding-left: 5px;
}

.project-info-shortcode-style-default .project-info-shortcode-item .title {
	padding-left: 50px;
}
.project-info-shortcode-style-default .project-info-shortcode-item .decription {
	padding-left: 50px;
	padding-top: 15px;
}
.widget.project_info .gem-button {
	margin-left: 0px;
}
.widget .project-info-button{
	text-align: center;
}

#page,
.widget_submenu  ul ul ul,
.widget_nav_menu  ul ul ul,
.widget_pages ul ul ul,
.widget_categories ul ul ul,
.widget_product_categories ul ul ul {
	background-color: #e8ecef;
}


.widget a.gem-button.gem-button-style-outline {
	background: transparent;
}

.gmaps_round {
	border-radius: 50%;
	overflow: hidden;
}
body .wpb_gmaps_widget .wpb_wrapper {
	padding: 0;
}
.vc_gem_pricing_table  .vc_controls-container.vc_controls {
	margin-top: 10px;
	z-index: 10;
}
@media (max-width: 600px) {
	body .vc_separator.vc_sep_double {
		height: auto !important;
	}
}


@media(max-width: 1024px){
	.fullwidth-block.fullwidth-block-parallax-vertical,
	.fullwidth-block.fullwidth-block-parallax-fixed{
		background-attachment: scroll;
	}
}
.textwidget img {
	max-width: 100%;
	height: auto;
}
.textwidget option,
.textwidget select {
	max-width: 100%;
}
.widget .screen-reader-text {
	margin-bottom: 10px;
	display: block;
}
.calendar_wrap td, .calendar_wrap th {
	border: none !important;
	padding: 5px !important;
}

.gem-icon-with-title  > a{
	display: table-cell;
	padding-bottom: 0 !important;
	vertical-align: middle;
	padding-right: 10px;
}
